<!-- eslint-disable sonarjs/no-duplicate-string -->
<template>
  <Sect id="SectionApvaInfo">
    <Row>
      <Column class="leftSide" :md="6">
        <Wrap class="xs">
          <h2>Gaukite APVA paramą saulės elektrinei</h2>
          <!-- <p>{{ injectSnippets('Paraiškas paramoms nutolusių saulės parkų dalims galite teikti iki {apvaStage}, arba kol paraiškose prašoma suma pasieks visą kvietimui numatytą sumą.') }}</p> -->
          <p>Netrukus paskelbsime 2025 m. paramos laikotarpį. Užsiregistruokite ir mes Jums pranešime.</p>
          <div class="ctaContainer">
            <a class="Btn arrow invert" href="https://apvis.apva.lt/" target="_blank">
              Daugiau informacijos
            </a>
            <a class="Btn arrow invert" href="https://urbanwebstack-uploadspublicbucket-52in24jckklk.s3.amazonaws.com/Kaip_pateikti_islaidu_kompensavimo_prasyma_APVA_sistemoje_2_a80eccf8d4.pdf" target="_blank">
              Pildymo instrukcija
            </a>
            <Route class="Btn arrow invert" to="Signup">
              Registruotis
            </Route>
          </div>
        </Wrap>
      </Column>
      <Column class="rightSide" :md="6">
        <Wrap class="xs">
          <h2>Užsisakykite nemokamą konsultaciją</h2>
          <p>Susisiekite su „Saulės bendruomenės“ specialistais, kurie atsakys į visus su parama susijusius klausimus:</p>
          <div class="ctaContainer">
            <Route class="Btn arrow invert contact" to="Contact">
              Nemokama konsultacija
            </Route>
          </div>
        </Wrap>
      </Column>
    </Row>
  </Sect>
</template>

<script>
import { getters } from 'views/utils'

export default {
  computed: getters('injectSnippets'),
}
</script>

<style lang="scss">
#SectionApvaInfo {
  color: $white;
  padding-bottom: 0;

  // .Btn {
  //   border: 1px solid $white;
  //   color: $white;
  // }

  > .Row {
    flex-direction: row;

    > .Column {
      display: flex;
      padding-bottom: 5rem;
      padding-top: 5rem;

      > .Wrap {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        justify-content: space-between;
      }
    }
  }

  .leftSide {
    background: linear-gradient(0deg, rgba($blue, 0.8), rgba($blue, 0.8)), url('../../../assets/index/panels.jpg');
    color: $white;
    // padding: 3rem 0 1rem;
    position: relative;

    .instructions {
      padding: 0 1rem;
    }
  }

  .rightSide {
    background: $black-bis;
    background-image: url('../../../assets/lines.svg');
    background-position: bottom left;
    // background-repeat: no-repeat;
    background-size: 40vw;
  }

  .leftSide, .rightSide {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .ctaContainer {
    width: 20rem;

    > * {
      margin-bottom: 1rem;
    }

    .minimalistic {
      color: $white;

      .circle {
        color: $white;
      }
    }
  }

  h2 {
    color: inherit;
    text-align: left;
    // font-size: 1rem;
    // @include lg {
    //   font-size: 3rem;
    // }
  }

  p {
    line-height: 1.6;
  }

  .Btn.contact {
    max-width: 20rem;
  }
}
</style>
