export const BRANCH_NAMES = {
  2020: '2020',
  2022: '2022',
  '2022_V2': '2022_V2',
  2024: '2024',
}

export const PROJECT_NAMES = {
  2020: 'Saulės Parkas Nr.1',

  2022: 'Saulės Parkas Nr.2',
  '2022_SKIPPABLE': 'Saulės Parkas Nr.3',

  '2022_V2': 'Saulės Parkas Nr.4',
  '2022_V2_SKIPPABLE': 'Saulės Parkas Nr.5',

  '2024_UNSUBMITTED': 'Saulės Parkas Nr.6',
  '2024_ADVANCE_UNSKIPPABLE': 'Saulės Parkas Nr.7',
  '2024_ADVANCE_SKIPPABLE': 'Saulės Parkas Nr.8',
}

export const PROJECT_BRANCHES = {
  [PROJECT_NAMES[2020]]: BRANCH_NAMES[2020],
  [PROJECT_NAMES[2022]]: BRANCH_NAMES[2022],
  [PROJECT_NAMES['2022_SKIPPABLE']]: BRANCH_NAMES[2022],
  [PROJECT_NAMES['2022_V2']]: BRANCH_NAMES['2022_V2'],
  [PROJECT_NAMES['2022_V2_SKIPPABLE']]: BRANCH_NAMES['2022_V2_SKIPPABLE'],

  [PROJECT_NAMES['2024_UNSUBMITTED']]: BRANCH_NAMES[2024],
  [PROJECT_NAMES['2024_ADVANCE_UNSKIPPABLE']]: BRANCH_NAMES[2024],
  [PROJECT_NAMES['2024_ADVANCE_SKIPPABLE']]: BRANCH_NAMES[2024],

}

export const CAPACITY_LIMIT_INDIVIDUAL = 200
export const CAPACITY_DEFAULT = 2.3
export const PRICE_PER_KW = 950
export const MAINTENANCE_FEE = 19
export const PASSWORD = 'testtest1'

export const PERSON_CODE = '33333333336'

export const IBAN = 'LT60 1010 0123 4567 8901'

export const PAYMENTS = {
  RESERVATION: 'paymentReservation',
  ADVANCE: 'paymentAdvance',
  MAIN: 'paymentMain',
  MAINTENANCE: 'paymentMaintenance',
}

export const CONTRACTS = {
  RESERVATION: 'contractReservation',
  MAIN: 'contractMain',
  TRANSFER_ACCEPTANCE: 'contractTransferAcceptance',
}
