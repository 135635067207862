<template>
  <Sect id="SectionReasons">
    <Wrap class="lg">
      <Row>
        <Column v-for="(card, index) in cards" :key="index" :md="6">
          <CardSemiFlip :card="card" />
        </Column>
      </Row>
    </Wrap>
    <Wrap class="md text">
      <p>
        Kad galėtumėte naudotis „Saulės bendruomenės“ privalumais, skirstomųjų tinklų operatorius Jūsų namuose įrengs Išmaniuosius elektros energijos skaitiklius ir tapsite gaminančiu vartotoju.
      </p>
      <p>
        Kiekvieno mėnesio pabaigoje, skirstomųjų tinklų operatorius pateiks sąskaitą už pasinaudojimą elektros tinklais („elektros pasaugojimą“). Jeigu Jūsų saulės elektrinės dalies sugeneruoto energijos kieko nepakaks Jūsų poreikiams padengti, taip pat gausite papildomą sąskaitą už trūkstamo kieko įsigijimą.
      </p>
      <p>
        Nesunaudotas elektros kiekis atiduodamas tinklams neatlygintinai kiekvienų metų kovo 31 d., todėl labai svarbu parinkti tinkamo dydžio elektrinės dalį.
      </p>
    </Wrap>
    <div class="contactContainer">
      <Route class="Btn arrow contact" to="Contact">
        Nemokama konsultacija
      </Route>
    </div>
  </Sect>
</template>

<script>
import CardSemiFlip from './CardSemiFlip'

export default {
  components: { CardSemiFlip },
  data() {
    return {
      cards: [
        {
          image: require('../../../assets/howItWorks/vartojimas.jpg'),
          icon: 'apartment-building',
          label: 'Energijos vartojimas namuose',
          text: 'Daugiabučių gyventojai suvartoja apie 3000 kWh elektros per metus.',
        },
        {
          image: require('../../../assets/howItWorks/generavimas.jpg'),
          icon: 'solar-module',
          label: 'Energijos generavimas saulės parke',
          text: 'Suvartojant apie 3000 kWh elektros per metus, siūlome įsigyti 3 kW "Saulės bendruomenės" parko dalį.',
        },
        {
          image: require('../../..//assets/howItWorks/tiekimas.jpg'),
          icon: 'power-lines',
          label: 'Elektros energijos tiekimas į tinklus',
          text: '"Saulės bendruomenės" parke pagaminta elektros energija yra patiekiama į tinklus.',
        },
        {
          image: require('../../../assets/howItWorks/sanaudos.jpg'),
          icon: 'apartment-building',
          label: 'Sąnaudų padengimas',
          text: 'Tinkluose atsidūrusi nutolusios elektrinės energija pasieks Jūsų namus ir padengs visus elektros poreikius.',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
#SectionReasons {
  background: $white-ter;
  display: flex;
  flex-direction: column;
  // padding: 15rem 0 8rem;
  position: relative;

  h2 {
    @include dotAbove(1.5rem);
    margin-bottom: 4rem;
  }

  .CardSemiFlip {
    margin: 1rem;
  }

  .text {
    margin-bottom: 5rem;
    margin-top: 5rem;

    p {
      margin-bottom: 2rem;
    }
  }

  .contactContainer {
    display: flex;
    justify-content: center;

    .Btn.contact {
      max-width: 20rem;
      text-align: center;
    }
  }
}
</style>
