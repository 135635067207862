import { hasRole, loggedIn } from './authorization/conditions'
import Index from '../views/Index'
import MainLayout from '../views/layouts/Main'

export default [
  {
    path: '',
    component: MainLayout,
    children: [
      {
        path: '/finansavimas',
        component: () => import(/* webpackChunkName: "pages" */ '../views/layouts/Financing'),
        children: [
          {
            path: 'inbank',
            name: 'FinancingInbank',
            component: () => import(/* webpackChunkName: "pages" */ '../views/FinancingInbank'),
          },
          {
            path: 'swedbank',
            name: 'FinancingSwedbank',
            component: () => import(/* webpackChunkName: "pages" */ '../views/FinancingSwedbank'),
          },
          {
            path: 'bigbank',
            name: 'FinancingBigbank',
            component: () => import(/* webpackChunkName: "pages" */ '../views/FinancingBigbank'),
          },
          {
            path: '',
            name: 'Financing',
            component: () => import(/* webpackChunkName: "pages" */ '../views/Financing'),
          },
        ],
      },
      {
        path: '/page',
        component: () => import(/* webpackChunkName: "pages" */ '../views/layouts/Page'),
        children: [
          {
            path: '/kontaktai',
            alias: '/susisiekite',
            name: 'Contact',
            component: () => import(/* webpackChunkName: "pages" */ '../views/Contact'),
          },
          {
            path: '/projektai/:slug',
            name: 'ProjectDetails',
            component: () => import('../views/ProjectDetails'),
          },
          {
            path: '/kaip-tai-veikia',
            name: 'HowItWorks',
            component: () => import('../views/HowItWorks'),
          },
          {
            path: '/apie-mus',
            name: 'AboutUs',
            component: () => import('../views/AboutUs-copy'),
          },
          {
            path: '/apva-gyventojams',
            name: 'ApvaIndividuals',
            component: () => import('../views/Apva/ApvaIndividuals'),
          },
          {
            path: '/saules-parkai',
            name: 'SolarPlants',
            component: () => import('../views/SolarPlants'),
          },
          {
            path: '/naujienos',
            name: 'News',
            component: () => import('../views/News'),
          },
          {
            path: '/naujienos/:slug',
            name: 'Post',
            component: () => import('../views/Post'),
          },
          {
            path: '/testing',
            name: 'Testing',
            component: () => import('../views/Testing'),
          },
          {
            path: '/skaiciuokle',
            name: 'Calculator',
            component: () => import(/* webpackChunkName: "pages" */ '../views/Calculator'),
          },
          {
            path: '/duk',
            name: 'Faq',
            component: () => import(/* webpackChunkName: "pages" */ '../views/Faq'),
          },
          {
            path: '/privatumo-politika',
            name: 'PrivacyPolicy',
            component: () => import(/* webpackChunkName: "policy" */ '../views/PrivacyPolicy'),
          },
          {
            path: '/svecias/projektas/:id/rezervuoti',
            name: 'SelfReservationAddGuest',
            redirect: { name: 'Signup' },
          },
          // {
          //   path: '/svecias/projektas/:id/rezervuoti',
          //   name: 'SelfReservationAddGuest',
          //   component: () =>
          //     import(/* webpackChunkName: "pages" */ '../views/SelfReservationAdd'),
          // },
          // {
          //   path: '/svecias/projektas/:id/rezervuoti/:amount/mano-info',
          //   name: 'ReserveGuest',
          //   component: () => import(/* webpackChunkName: "pages" */ '../views/ReserveGuest'),
          // },
        ],
      },
      {
        path: '/auth',
        component: () => import(/* webpackChunkName: "form" */ '../views/layouts/AuthNew'),
        children: [
          {
            path: '/atsijungimas',
            name: 'Logout',
            component: () => import(/* webpackChunkName: "form" */ '../views/Logout'),
          },
          {
            path: '/prisijungimas',
            name: 'Login',
            component: () => import(/* webpackChunkName: "form" */ '../views/Login'),
          },
          {
            path: '/prisijungimas/pamirsau',
            name: 'LoginForgot',
            component: () => import(/* webpackChunkName: "form" */ '../views/LoginForgot'),
          },
          {
            path: '/prisijungimas/atkurti',
            name: 'LoginReset',
            component: () => import(/* webpackChunkName: "form" */ '../views/LoginReset'),
          },
          {
            path: '/registracija',
            name: 'Signup',
            component: () => import(/* webpackChunkName: "form" */ '../views/Signup'),
          },
          {
            path: '/registracija/dekui',
            name: 'SignupThanks',
            component: () => import(/* webpackChunkName: "form" */ '../views/SignupThanks'),
          },
          {
            path: '/registracija/patvirtinti',
            name: 'SignupVerify',
            component: () => import(/* webpackChunkName: "form" */ '../views/SignupVerify'),
          },
          {
            path: '/email/block',
            name: 'EmailBlock',
            component: () => import(/* webpackChunkName: "form" */ '../views/EmailBlock'),
          },
        ],
      },
      {
        path: '/dummy-path',
        component: () => import(/* webpackChunkName: "self" */ '../views/layouts/SelfService'),
        auth: loggedIn(),
        children: [
          {
            path: '/savitarna/projektas/:id/rezervuoti',
            name: 'SelfReservationAdd',
            component: () =>
              import(/* webpackChunkName: "self" */ '../views/SelfReservationAdd'),
          },
          {
            path: '/savitarna/projektas/:id/rezervuoti/perziura/:reservationId',
            name: 'SelfReservationAddPreview',
            component: () =>
              import(/* webpackChunkName: "self" */ '../views/SelfReservationAdd'),
          },
          {
            path: '/savitarna/projektas/:id/rezervuoti/:amount/mano-info',
            name: 'ReserveUser',
            component: () => import(/* webpackChunkName: "self" */ '../views/ReserveUser'),
          },
          {
            path: '/savitarna/projektas/:id/rezervuoti/mano-info/perziura/:reservationId',
            name: 'ReserveUserPreview',
            component: () => import(/* webpackChunkName: "self" */ '../views/ReserveUser'),
          },
          {
            path: '/rezervacija/:reservationId/mokejimas/:id/paysera',
            name: 'PaymentPaysera',
            component: () => import(/* webpackChunkName: "self" */ '../views/PaymentPaysera'),
          },

          // Savitarnos vidus
          {
            path: '/savitarna',
            name: 'SelfServiceLayout',
            component: () => import(/* webpackChunkName: "self" */ '../views/layouts/SelfServiceInner'),
            auth: loggedIn(),
            children: [
              {
                path: 'profilis',
                name: 'SelfProfile',
                component:
                  () => import(/* webpackChunkName: "self" */ '../views/SelfProfile'),
              },
              {
                path: 'pranesimai',
                name: 'SelfMessages',
                component:
                  () => import(/* webpackChunkName: "self" */ '../views/SelfMessages'),
              },
              {
                path: 'uzsakymai',
                name: 'SelfReservations',
                component:
                  () => import(/* webpackChunkName: "self" */ '../views/SelfReservations'),
              },
              {
                path: 'rezervacija/:id',
                name: 'Reservation',
                component: () => import(/* webpackChunkName: "self" */ '../views/Reservation'),
              },
              {
                path: 'statistika/:id?',
                name: 'ReservationStats',
                component: () => import(/* webpackChunkName: "self" */ '../views/ReservationStats'),
              },
              {
                path: 'projektai',
                name: 'SelfProjects',
                component: () => import(/* webpackChunkName: "self" */ '../views/SelfProjects'),
              },
              {
                path: 'projektas/:id/progresas',
                name: 'SelfProjectProgress',
                component: () =>
                  import(/* webpackChunkName: "self" */ '../views/SelfProjectProgress'),
              },
            ],
          },
        ],
      },
      // {
      //   path: '/konsultacija',
      //   name: 'Landing',
      //   component: () => import(/* webpackChunkName: "landing2020" */ '../views/Landing'),
      // },
      {
        path: '',
        name: 'Index',
        component: Index,
      },
      // {
      //   path: '/projektai/:slug',
      //   name: 'ProjectDetails',
      //   component: () => import('../views/ProjectDetails'),
      // },
      // {
      //   path: '/kaip-tai-veikia',
      //   name: 'HowItWorks',
      //   component: () => import('../views/HowItWorks'),
      // },
      // {
      //   path: '/apie-mus',
      //   name: 'AboutUs',
      //   component: () => import('../views/AboutUs-copy'),
      // },
      // {
      //   path: '/apva-gyventojams',
      //   name: 'ApvaIndividuals',
      //   component: () => import('../views/Apva/ApvaIndividuals'),
      // },
      // {
      //   path: '/saules-elektrines',
      //   name: 'SolarPlants',
      //   component: () => import('../views/SolarPlants'),
      // },
      // {
      //   path: '/naujienos',
      //   name: 'News',
      //   component: () => import('../views/News'),
      // },
      // {
      //   path: '/naujienos/:slug',
      //   name: 'Post',
      //   component: () => import('../views/Post'),
      // },
      // {
      //   path: '/testing',
      //   name: 'Testing',
      //   component: () => import('../views/Testing'),
      // },
    ],
  },
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/layouts/Admin'),
    auth: hasRole('>=SUPPORT'),
    children: [
      {
        path: 'dashboard',
        name: 'AdminDashboard',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminDashboard'),
      },
      {
        path: 'contracts',
        name: 'AdminContracts',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminContracts'),
      },
      {
        path: 'emails',
        name: 'AdminMail',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminMail'),
      },
      {
        path: 'eso',
        name: 'AdminEsoSnapshots',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminEsoSnapshots'),
      },
      {
        path: 'eso/add',
        name: 'AdminEsoSnapshotAdd',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminEsoSnapshotAdd'),
      },
      {
        path: 'eso/:id',
        name: 'AdminEsoSnapshot',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminEsoSnapshot'),
      },
      {
        path: 'user/:id',
        name: 'AdminUser',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminUser'),
      },
      {
        path: 'users',
        name: 'AdminUsers',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminUsers'),
      },
      {
        path: 'projects',
        name: 'AdminProjects',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminProjects'),
      },
      {
        path: 'projects/add',
        name: 'AdminProjectsAdd',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminProjectsAdd'),
      },
      {
        path: 'projects/:id',
        name: 'AdminProject',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminProject'),
      },
      {
        path: 'projects/:id/posts',
        name: 'AdminProjectPosts',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminProjectPosts'),
      },
      {
        path: 'projects/:id/posts/add',
        name: 'AdminProjectPostAdd',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminProjectPostAdd'),
      },
      {
        path: 'projects/:projectId/posts/:id',
        name: 'AdminProjectPost',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminProjectPost'),
      },
      {
        path: 'steps',
        name: 'AdminSteps',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminSteps'),
      },
      {
        path: 'steps/add',
        name: 'AdminStepAdd',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminStepAdd'),
      },
      {
        path: 'steps/timeline',
        name: 'AdminStepsTimeline',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminStepsTimeline'),
        auth: hasRole('>=ADMIN'),
      },
      {
        path: 'steps/:id',
        name: 'AdminStep',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminStep'),
      },
      {
        path: 'messages',
        name: 'AdminMessages',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminMessages'),
      },
      {
        path: 'messages/add',
        name: 'AdminMessageAddMultiple',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminMessageAddMultiple'),
      },
      {
        path: 'messages/add/:userId',
        name: 'AdminMessagesAdd',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminMessagesAdd'),
      },
      {
        path: 'payments',
        name: 'AdminPayments',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminPayments'),
      },
      {
        path: 'promos',
        name: 'AdminPromos',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminPromos'),
      },
      {
        path: 'promos/add',
        name: 'AdminPromosAdd',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminPromosAdd'),
      },
      {
        path: 'promos/:id',
        name: 'AdminPromo',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminPromo'),
      },
      {
        path: 'logs',
        name: 'AdminLogs',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminLogs'),
        auth: hasRole('>=ADMIN'),
      },
      {
        path: 'reservations',
        name: 'AdminReservations',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminReservations'),
      },
      {
        path: 'reservations/:id',
        name: 'AdminReservation',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminReservation'),
      },
      {
        path: 'flags',
        name: 'AdminFeatureFlags',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminFeatureFlags'),
        auth: hasRole('SUPERADMIN'),
      },
      {
        path: 'payments-generate',
        name: 'AdminPaymentGenerate',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminPaymentGenerate'),
        auth: hasRole('SUPERADMIN'),
      },
      {
        path: 'settings',
        name: 'AdminSettings',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminSettings'),
      },
      {
        path: 'solarInverters',
        name: 'AdminSolarInverters',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminSolarInverters'),
      },
      {
        path: 'solarInverters/add',
        name: 'AdminSolarInverterAdd',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminSolarInverterAdd'),
      },
      {
        path: 'solarInverters/:id',
        name: 'AdminSolarInverter',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminSolarInverter'),
      },
      {
        path: 'solarModules',
        name: 'AdminSolarModules',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminSolarModules'),
      },
      {
        path: 'solarModules/add',
        name: 'AdminSolarModuleAdd',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminSolarModuleAdd'),
      },
      {
        path: 'solarmodules/:id',
        name: 'AdminSolarModule',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminSolarModule'),
      },
      {
        path: 'snippets',
        name: 'AdminSnippets',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminSnippets'),
      },
      {
        path: 'snippets/add',
        name: 'AdminSnippetAdd',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminSnippetAdd'),
      },
      {
        path: 'snippets/:id',
        name: 'AdminSnippet',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminSnippet'),
      },
    ],
  },
  {
    path: '/pasiulymas',
    name: 'LandingSubsidy',
    component: () => import(/* webpackChunkName: "landing" */ '../views/LandingSubsidy'),
  },
  {
    path: '/oauth/:provider?',
    name: 'OAuth',
    component: () => import(/* webpackChunkName: "form" */ '../views/OAuth'),
  },
  // {
  //   path: '/atsijungimas',
  //   name: 'Logout',
  //   component: () => import(/* webpackChunkName: "form" */ '../views/Logout'),
  // },
  {
    path: '/denied',
    name: 'Err401',
    component: () => import(/* webpackChunkName: "ext" */ '../views/errors/401.vue'),
  },
  {
    path: '*',
    name: 'Err404',
    component: () => import(/* webpackChunkName: "ext" */ '../views/errors/404.vue'),
  },
]
