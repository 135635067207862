<template>
  <Sect id="SectionBenefits">
    <Wrap class="lg">
      <Row>
        <Column class="item" :lg="4">
          <Icon name="bill-fancy" />
          <h3 class="label">
            Mažesnės sąskaitos už elektros energiją
          </h3>
          <p>
            Įsigiję nutolusios saulės elektrinės dalį ir pradėję naudoti saulės energiją savo buityje, sulauksite net iki 70 % mažesnių elektros sąskaitų.
          </p>
        </Column>
        <Column class="item" :lg="4">
          <Icon name="hand-gear" />
          <h3 class="label">
            Priežiūra pasirūpinsime mes
          </h3>
          <p>
            Mes pasirūpinsime visais saulės elektrinės įrengimo etapais: pradedant saulės elektrinės laukuose statyba, baigiant nuolatine saulės elektrinės priežiūra ir aptarnavimu.
          </p>
        </Column>
        <Column class="item" :lg="4">
          <Icon name="bulb-fancy" />
          <h3 class="label">
            Atsakingas vartojimas ir švaresnė aplinka
          </h3>
          <p>
            Žalios energijos vartojimas – efektyviausias būdas prisidėti prie klimato kaitos mažinimo. Naudodama atsinaujinčią saulės energiją per metus trijų asmenų šeima gali sutaupyti iki 2 tonų CO2. Tokį kiekį CO2 sumažinti padeda 350 vnt. medžių.
          </p>
        </Column>
      </Row>
    </Wrap>
  </Sect>
</template>

<script>
export default {
}
</script>

<style lang="scss">
#SectionBenefits {
  background: $white-ter;
  // background: linear-gradient(0deg, rgba($blue, 0.8), rgba($blue, 0.8)), url('../../../assets/index/panels.jpg');
  // background-blend-mode: overlay, darken;
  background-size: 120%;
  color: $black-ter;
  // font-weight: $bold;
  margin: 0 0 8rem;
  // padding: 3rem 0 1rem;
  // padding-top: 8rem;
  position: relative;
  @include md {
    margin: 0 0 16rem;
  }

  h3 {
    color: $grey-darker;
    font-size: 1.8rem;
    font-weight: $bold;
    min-height: 4rem;
  }

  .Row {
    align-items: flex-start;
  }

  .item {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    padding: 2rem;
    text-align: center;

    > p {
      line-height: 1.5;
    }

    > .Icon {
      color: $blue;
      font-size: 6rem;
    }
  }

}
</style>
