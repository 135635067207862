<template>
  <Wrap>
    <Breadcrumbs
      v-if="project"
      creationBreadcrumbKey="INFO"
      :hasSkippedAdvance="hasSkippedAdvanceStatus"
      :hasSkippedReservation="hasSkippedReservationStatus"
      :project="project"
      :reservationId="reservationId" />
    <Sect>
      <h2>Užsakovo duomenys</h2>
      <FormValidation @submit="submit">
        <transition name="slide-fade">
          <div v-if="!isLoading">
            <Notice class="warning">
              <template v-if="hasSufficientProfile">
                Įsitikinkite ar jūsų duomenys teisingi
              </template>
              <template v-else>
                Užpildykite trūkstamus duomenis
              </template>
            </Notice>
            <FormUser
              :isCompanyAlready="isCompanyAlready"
              :isPhoneFieldDisabled="isInReviewMode"
              type="order"
              :user="user" />
            <FieldSkipReservation
              v-if="project && project.canSkipReservation && isReservationPaymentSupported"
              :advanceAmountDefault="project.advancePaymentDefaultPercent"
              :disabled="isInReviewMode"
              :value.sync="hasSkippedReservation" />
            <FieldCustomAdvancePayment
              v-if="project && project.isAdvancePaymentCustomizable"
              :amountDefault="project.advancePaymentDefaultPercent"
              :disabled="isInReviewMode"
              :range="[project.advancePaymentPercentMin, project.advancePaymentPercentMax]"
              :value.sync="hasCustomAdvancePayment" />
            <FieldCustomAdvanceSlider
              v-if="project && hasCustomAdvancePayment"
              :isDisabled="isInReviewMode"
              label="Norimas avanso dydis"
              :max="100"
              :min="35"
              :value.sync="advancePaymentPercent" />
            <FieldSkipAdvance
              v-if="project && project.canSkipAdvance"
              :advanceAmountDefault="project.advancePaymentDefaultPercent"
              :disabled="isInReviewMode"
              :value.sync="hasSkippedAdvance" />
            <Field
              data-cy="promo"
              :disabled="isInReviewMode"
              label="Nuolaidos kodas (Promo)"
              :maxlength="50"
              :value.sync="promoCode" />
            <Field
              data-cy="apvaChoice"
              :disabled="isInReviewMode"
              label="Valstybės parama"
              :options="apvaOptions"
              required
              type="select"
              :value.sync="apvaChoice" />
            <Field
              v-if="!isInReviewMode && apvaChoice === 'HAS_APVA' && apvaStageOptions.length > 1"
              label="Pasirinkite APVA etapą"
              :options="apvaStageOptions"
              :required="apvaChoice === 'HAS_APVA'"
              type="select"
              :value.sync="apvaStageId" />
            <Field
              v-if="isInReviewMode && apvaStagePreview"
              disabled
              label="APVA etapas"
              type="text"
              :value="apvaStagePreview" />
          </div>
        </transition>
        <transition appear name="slide-fade">
          <Notice
            v-if="isLoading"
            id="please-wait-contract"
            class="info">
            Prašome palaukti, ruošiama sutartis. Tai turėtų užtrukti apie 10 sekundžių.
          </Notice>
        </transition>
        <Btn
          v-if="!isInReviewMode"
          class="arrow"
          :isLoading="isLoading"
          type="submit">
          Išsaugoti ir kurti sutartį
        </Btn>
      </FormValidation>
    </Sect>
  </Wrap>
</template>

<script>
import { actions, clone, getters } from './utils'
import { APVA_CHOICES } from '@/store/consts'
import Breadcrumbs from './components/Breadcrumbs'
import FieldCustomAdvancePayment from 'components/fields/FieldCustomAdvancePayment'
import FieldCustomAdvanceSlider from 'components/FieldCustomAdvanceSlider'
import FieldSkipAdvance from 'components/fields/FieldSkipAdvance'
import FieldSkipReservation from 'components/fields/FieldSkipReservation'
import FormUser from './components/forms/FormUser'

export default {
  components: {
    Breadcrumbs,
    FieldSkipReservation,
    FieldCustomAdvancePayment,
    FieldCustomAdvanceSlider,
    FieldSkipAdvance,
    FormUser,
  },
  data() {
    return {
      reservationId: Number(this.$route.params.reservationId),

      hasSkippedReservation: false,
      hasCustomAdvancePayment: false,
      advancePaymentPercent: this.project
        ? this.project.advancePaymentDefaultPercent
        : 50,
      hasSkippedAdvance: false,
      isCompanyAlready: false,
      isLoading: false,
      promoCode: null,
      user: {},
      apvaOptions: Object.entries(APVA_CHOICES),
      apvaChoice: Object.keys(APVA_CHOICES)[0],
      apvaStageId: null,
    }
  },
  computed: {
    apvaStagePreview() {
      if (!this.reservation || !this.reservation.apvaStageId) return

      const stage = this.query('apvaStage', this.reservation.apvaStageId)
      if (!stage) return
      return stage.startDate.toLocaleString('lt-LT', { year: 'numeric', month: 'long', day: 'numeric' })
    },
    apvaStageOptions() {
      return [
        [null, 'Pasirinkite etapą'],
        ...this.query('apvaStage', {
          isHidden: false,
          $sort: { startDate: -1 },
        })
          .map(apvaStage => [
            apvaStage.id,
            apvaStage.startDate
              .toLocaleString('lt-LT', { year: 'numeric', month: 'long', day: 'numeric' }),
          ]),
      ]
    },
    isInReviewMode() {
      return !!this.$route.params.reservationId
    },
    amount() {
      return Number(this.$route.params.amount) / 100
    },
    project() {
      return this.query('project', this.$route.params.id)
    },
    reservation() {
      if (!this.isInReviewMode) return
      return this.query('reservation', this.$route.params.reservationId)
    },
    hasSkippedReservationStatus() {
      if (!this.isInReviewMode) return this.hasSkippedReservation
      return this.reservation.hasSkippedReservation
    },
    hasSkippedAdvanceStatus() {
      if (!this.isInReviewMode) return this.hasSkippedAdvance
      return this.reservation.hasSkippedAdvance
    },
    isReservationPaymentSupported() {
      return this.doesStepBranchSupportReservation(this.project.stepBranch)
    },
    ...getters('hasSufficientProfile', 'doesStepBranchSupportReservation'),
  },
  methods: {
    async getAlreadyUsedPromoCode() {
      if (!this.reservation || !this.reservation.promoCodeId) return

      const promoCode = await this.GET_PROMO_CODE(this.reservation.promoCodeId)

      this.promoCode = promoCode.code
    },
    async submit() {
      try {
        this.isLoading = true

        await this.PATCH_USER(this.user)

        const { id } = await this.CREATE_RESERVATION({
          capacity: this.amount,
          // Assuming that Reservation payment is skipped if Advance payment skipped
          hasSkippedReservation: this.hasSkippedAdvance
            ? true
            : this.hasSkippedReservation,
          advancePaymentPercent: this.hasCustomAdvancePayment
            ? this.advancePaymentPercent
            : null,
          hasSkippedAdvance: this.hasSkippedAdvance,
          projectId: this.project.id,
          promoCode: this.promoCode,
          apvaChoice: this.apvaChoice,
          apvaStageId: this.apvaStageId,
          userId: this.authUser.id,
        })

        this.SET_CAPACITY_CACHE(null)

        this.$router.push({
          name: 'Reservation',
          params: {
            id,
            projectId: this.project.id,
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    ...actions(
      'FETCH_RESERVATION',
      'CREATE_RESERVATION',
      'PATCH_USER',
      'GET_PROMO_CODE',
      'SET_CAPACITY_CACHE',
      'FETCH_APVA_STAGE',
    ),
  },
  async created() {
    await this.LOAD(() => Promise.all([
      this.FETCH_APVA_STAGE(),
      this.FETCH_RESERVATION({ userId: this.authUser.id }),
    ]))

    this.user = clone(this.authUser)
    this.isCompanyAlready = this.user.isCompany

    if (!this.user.country) this.user.country = 'LT'

    if (this.isInReviewMode) {
      this.getAlreadyUsedPromoCode()
      this.hasSkippedReservation = this.hasSkippedReservationStatus
      this.apvaChoice = this.reservation.apvaChoice
    }
  },
}
</script>

<style lang="scss">
#please-wait-contract {
  transition-delay: 0.6s;
}
// .Wrap {
//   .ProgressBar {
//     .ProgressBarStep {
//       width: calc(100% / 3);
//     }
//   }
// }
</style>
