<template>
  <Route class="MainLogo" :exact="true" title="Pagrindinis" to="Index">
    <Pic
      alt="Saulės Bendruomenė"
      src="../../assets/logo.svg" />
  </Route>
</template>

<script>
export default {}
</script>

<style lang="scss">
.MainLogo {
  border: 0;
  display: inline-block;
  padding: 0.5rem 0;
  position: relative;
  z-index: 12;
  @include md {
    padding: 1.25rem 0;
  }
  @include lg {
    padding-top: 1.5rem;
  }

  img {
    height: 30px;
    transition-duration: 0.3s;
    transition-property: height;
    transition-timing-function: ease;
    @include sm {
      height: 35px;
    }
    // @include md {
    //   height: 35px;
    // }
    @include lg {
      height: 40px;
    }
    @include xxl {
      height: 50px;
    }
  }

  &.db {
    img {
      height: auto;
      width: 100%;
    }
  }

  &.router-link-exact-active {
    cursor: default;
  }
}
</style>
