import { getterOptions } from '../../utils'
import { prop } from 'rambda'

const PROJECT_STEP_BRANCHES = {
  2020: '2020',
  2022: '2022',
  '2022_V2': '2022_V2',
  2024: '2024',
}

const BRANCHES_SUPPORTING_RESERVATION_PAYMENT = [
  PROJECT_STEP_BRANCHES[2020],
  PROJECT_STEP_BRANCHES[2022],
  PROJECT_STEP_BRANCHES['2022_V2'],
]

const BRANCHES_SUPPORTING_SKIPPING_ADVANCE = [
  PROJECT_STEP_BRANCHES[2024],
]

const BRANCHES_SUPPORTING_MANUAL_CONTRACT_SIGNING = [
  PROJECT_STEP_BRANCHES[2024],
]

export const store = {
  state: {
    stepBranches: [
      {
        label: '2020 (sena)',
        value: PROJECT_STEP_BRANCHES[2020],
      },
      {
        label: '2022 (su rezervacine sut.)',
        value: PROJECT_STEP_BRANCHES[2022],
      },
      {
        label: '2022_V2 (apjungta sut.)',
        value: PROJECT_STEP_BRANCHES['2022_V2'],
      },
      {
        label: '2024',
        value: PROJECT_STEP_BRANCHES[2024],
      },
    ],
  },
  getters: {
    stepBranches: prop('stepBranches'),
    projectStepBranchOptions: getterOptions('stepBranches'),
    doesStepBranchSupportReservation: (_) => (value) => BRANCHES_SUPPORTING_RESERVATION_PAYMENT
      .includes(PROJECT_STEP_BRANCHES[value]),
    doesStepBranchSupportSkippingAdvance: (_) => (value) => BRANCHES_SUPPORTING_SKIPPING_ADVANCE
      .includes(PROJECT_STEP_BRANCHES[value]),
    doesStepBranchSupportManualSigning: (_) => (value) => BRANCHES_SUPPORTING_MANUAL_CONTRACT_SIGNING
      .includes(PROJECT_STEP_BRANCHES[value]),
  },
}
