<template>
  <ModalCont v-if="isOpen" class="PaymentModal" @close="close">
    <h3 v-if="doesNeedEso" class="name">
      Nurodykite savo Vartojimo Objektą
      <div class="description">
        kur bus naudojama sugeneruojama elektros energija
      </div>
    </h3>
    <FormValidation data-cy="esoForm" @submit="submitForm">
      <div v-if="doesNeedEso">
        <FormAdvancePayment
          :capacitySum="capacitySum"
          :consumptionObjects.sync="consumptionObjects"
          :disabled="isSaving"
          :reservation="reservation" />
        <div class="explanation">
          <b>Vartojimo objekto adresą</b> ir <b>numerį</b> galite rasti elektros tiekėjo savitarnos svetainėje. Atkreipkite dėmesį, kad <b>vartojimo objekto numeris</b> skiriasi nuo <b>kliento kodo</b>, taip pat matomo savitarnos svetainėje. <b>Vartojimo objekto numerį</b> rasite šalia vartojimo objekto adreso.
        </div>
      </div>
      <div v-if="payment.type === 'MAIN'" class="textNotice">
        Atkreipiame Jūsų dėmesį, kad tam, jog galėtumėte naudotis saulės generuojama galia, Jūsų nuosavybės teise valdomi vartojimo objektai turi būti prijungti prie <strong>AB „Elektros skirstymo operatoriaus“</strong> skirstomųjų tinklų.
      </div>
      <div class="textNotice">
        Informuojame, kad sąskaitos informacijos ir mokėjimo inicijavimo paslaugas Jums suteiks Paysera vadovaudamasi šių paslaugų teikimo
        <a
          href="https://www.paysera.lt/v2/lt-LT/sutartys/mip-taisykles-2020"
          rel="noopener noreferrer"
          target="_blank">
          taisyklėmis
        </a>.
        Tęsdami apmokėjimą patvirtinate, kad sutinkate su šių paslaugų suteikimu ir jų teikimo sąlygomis.
      </div>
      <Row class="actionsRow">
        <Column
          :xs="6">
          <Btn
            class="neutral"
            :disabled="isSaving"
            @click="close">
            Atšaukti
          </Btn>
        </Column>
        <Column
          :xs="6">
          <Btn
            class="danger"
            data-cy="payConfirm"
            :disabled="isSaving || !canSubmit"
            type="submit">
            Sumokėti
          </Btn>
        </Column>
      </Row>
    </FormValidation>
  </ModalCont>
</template>

<script>
import { actions, getters } from '../utils'
import FormAdvancePayment from './forms/FormAdvancePayment'
import { PAYMENT_TYPES } from '@/store/consts'
import { pluck } from 'rambda'

export default {
  components: { FormAdvancePayment },
  props: {
    isOpen: Boolean,
    reservation: Object,
    payment: Object,
  },
  data() {
    return {
      consumptionObjects: [
        {
          objectAddress: null,
          objectCapacity: this.reservation.capacity,
          objectNo: null,
        },
      ],
      isSaving: false,
    }
  },
  computed: {
    doGetEsoOnMainPayment() {
      return this.reservation.hasSkippedAdvance && this.payment.type === PAYMENT_TYPES.MAIN
    },
    doesNeedEso() {
      return this.payment.type === PAYMENT_TYPES.ADVANCE || this.doGetEsoOnMainPayment
    },
    canSubmit() {
      if (!this.doesNeedEso) return true

      // ADVANCE
      if (this.capacitySum !== this.reservation.capacity) return false

      return this
        .consumptionObjects
        .every(consumptionObject => consumptionObject.objectAddress &&
          consumptionObject.objectCapacity &&
          consumptionObject.objectNo)
    },
    capacitySum() {
      return Number(this
        .consumptionObjects
        .reduce((sum, { objectCapacity }) => sum + (objectCapacity || 0), 0)
        .toFixed(1))
    },
    ...getters('modal'),
  },
  methods: {
    async submitForm() {
      await this.$wrap(async () => {
        if (this.doesNeedEso) {
          const objectAddress = pluck('objectAddress', this.consumptionObjects)
          const objectCapacity = pluck('objectCapacity', this.consumptionObjects)
          const objectNo = pluck('objectNo', this.consumptionObjects)

          await this.PATCH_RESERVATION({
            id: this.reservation.id,
            objectAddress,
            objectCapacity,
            objectNo,
          })
        }

        await this.REDIRECT_TO_PAYSERA(this.payment.id)
      }, 'isSaving')
    },
    close() {
      this.$emit('close')
    },
    ...actions(
      'PATCH_RESERVATION',
      'REDIRECT_TO_PAYSERA',
    ),
  },
  created() {
    const { objectAddress, objectCapacity, objectNo } = this.reservation

    if (!objectAddress || !objectAddress.length) return

    this.consumptionObjects = objectAddress
      .map((currentObjectAddress, index) => ({
        objectAddress: currentObjectAddress,
        objectCapacity: objectCapacity[index],
        objectNo: objectNo[index],
      }))
  },
}
</script>

<style lang="scss">
.PaymentModal {
  .Modal {
    overflow-x: hidden;

    .name {
      padding: 1.5rem;
      text-align: center;
    }
  }

  .description {
    font-size: 1rem;
    font-weight: $medium;
  }

  .explanation {
    color: $red;
    margin: 1rem 1.5rem 0;
  }

  > .text {
    font-size: $h4;
    font-weight: $medium;
    padding: 2rem 2rem 1rem;
  }

  .textNotice {
    padding: 1.5rem 1.5rem 0;
  }

  .actionsRow {
    padding: 1.5rem;
  }
}
</style>
