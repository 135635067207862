<template>
  <div id="Contact">
    <Hero src="../assets/aboutUs/hero.jpg">
      <h1>Susisiekite</h1>
    </Hero>
    <FormContactNew
      slot="form"
      eventLabel="contact"
      :fields="[
        'firstName',
        'lastName',
        'email',
        'phone',
        'comment',
      ]" />
    <Sect class="sectionContacts">
      <!-- <Wrap class="md">
        <p class="text">
          Arba kviečiame susisiekti su mumis tiesiogiai:
        </p>
      </Wrap> -->
      <Wrap class="contacts-wrap">
        <Row class="contacts">
          <div class="contactItem">
            Tel. nr.: <Phone number="+370 633 82 277" />
          </div>
          <div class="contactItem">
            El. paštas: <Email address="info@saulesbendruomene.lt" />
          </div>
        </Row>
      </Wrap>
    </Sect>
  </div>
</template>

<script>
import FormContactNew from './components/forms/FormContactNew'
import Hero from './components/landing/Hero'
import Meta from './mixins/Meta'

export default {
  mixins: [
    Meta(
      'Susisiekite',
      'Nori prisijungti prie SAULĖS BENDRUOMENĖS? Susisiekite su mumis. Užpildyk žemiau esančią formą arba kreipkis tiesiai į mūsų pardavimų vadybininkus ir mes atsakysime į visus Tau kylančius klausimus. Jokių įsipareigojimų, jokių išankstinių mokėjimų.',
      require('@/assets/social/contact.jpg'),
    ),
  ],
  components: { Hero, FormContactNew },
}
</script>

<style lang="scss">
/* stylelint-disable plugin/stylelint-no-indistinguishable-colors */
$_purple-gradient: linear-gradient(#3d7ae5, #6133d3, #6030d1);

#Contact {
  background: $white-ter;
  @include maxMd {
    #Footer {
      background-image: $_purple-gradient;
    }
  }

  .Hero {
    @include roundSvg('../assets/ellipse.svg');

    h1 {
      @include dotBefore(8rem);
    }

    img {
      filter: brightness(0.4);
    }
  }

  .sectionContacts {
    padding-bottom: 4rem;
    position: relative;
    text-align: center;
    // @include lg {
    //   padding-bottom: 10rem;
    // }

    p {
      font-size: $h3;
      margin-bottom: 2rem;
    }
  }

  .Wrap.contacts-wrap {
    max-width: 850px;
    padding-bottom: 4rem;
    // @include md {
    //   padding-bottom: 6rem;
    // }

    .Row.contacts {
      align-items: center;
      display: flex;
      flex-direction: column;
      font-size: $h3;
      gap: 2rem;
      justify-content: center;
      line-height: $h4;
      @include lg {
        font-size: $h3;
        line-height: $lh;
      }

      .contactItem {
        line-height: $lh * 1.2;
        text-align: center;
      }

      a {
        margin: 0 1rem;
      }
    }
  }

  #FormContact {
    a, label {
      color: $white;
    }
  }
}
</style>
