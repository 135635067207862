<template>
  <div id="ProjectDetails">
    <Hero :src="project ? project.imageUrl : ''">
      <h1 class="title">
        {{ title }}
      </h1>
    </Hero>
    <div v-if="isLoading" class="loadingScreen">
      <Loader />
    </div>
    <template v-else>
      <Wrap class="lg project">
        <CardProject :coverLinkProp="routeReserve" :project="project" />

        <div v-if="isNotBuilt" class="disclaimer">
          <span>*Nuotraukoje matomas parkas nėra būsimo parko atitikmuo</span>
        </div>

        <div v-if="isProjectReservable" class="cta">
          <Route class="Btn reserve arrow" :to="routeReserve">
            Įsigyti
          </Route>
        </div>
      </Wrap>
      <ProjectSpecifications :project="project" />
      <div v-if="isProjectReservable" class="cta-secondary">
        <Route class="Btn arrow" :to="routeReserve">
          Įsigyti elektrinę šiame parke
        </Route>
      </div>
      <AboutProject :project="project" />
      <div v-if="isProjectReservable" class="cta-secondary">
        <Route class="Btn arrow reserve-secondary" :to="routeReserve">
          Įsigyti elektrinę šiame parke
        </Route>
      </div>
      <div class="location">
        <Pic :src="project.locationImageUrl" />
        <div class="info">
          <Icon name="pin" />
          <div class="address">
            {{ project.location }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// import { actions, getters } from 'views/utils'
import AboutProject from './components/AboutProject'
import CardProject from 'components/CardProject'
import Hero from 'components/landing/Hero'
// import Meta from 'views/mixins/Meta'
// import ProjectMocker from 'mixins/ProjectMocker'
import ProjectBundler from 'mixins/ProjectBundler'
import ProjectSpecifications from './components/ProjectSpecifications'

// import mockModules from './mock/modules.json'
// import mockProjects from './mock/projects.json'

// const Markdown = () => import(/* webpackChunkName: 'showdown' */ 'views/components/Markdown')

export default {
  mixins: [
    ProjectBundler,
    // Meta(
    //   ({ post }) => post?.title,
    //   ({ post }) => post?.content,
    //   require('@/assets/social/homepage.jpg'),
    // ),
  ],
  components: { AboutProject, CardProject, Hero, ProjectSpecifications },
  computed: {
    title() {
      if (!this.project) return ''
      return this.project.name.startsWith('Saulės Parkas')
        ? this.project.name
        : `Saulės Parkas „${this.project.name}“`
    },
    routeReserve() {
      const routeName = this.isAuth
        ? 'SelfReservationAdd'
        : 'SelfReservationAddGuest'

      return { name: routeName, params: { id: this.project.id } }
    },
    project() {
      const slugCurrent = this.$route.params.slug

      return this.projects.find(project => project.slug === slugCurrent)
    },
    isProjectReservable() {
      return this.project.capacity > this.project.capacityUsed
    },
    isNotBuilt() {
      return this.project.status !== 'BUILT'
    },
  },

}
</script>

<style lang="scss">
#ProjectDetails {
  background: $white-ter;

  .Btn.reserve-secondary {
    margin-top: 4rem;
  }

  .loadingScreen {
    height: 20vh;
  }

  .Hero {
    @include roundSvg('../../assets/ellipse.svg');

    img {
      filter: opacity(0.1);
      height: 100%;
      object-fit: fill;
      // object-position: center;
    }
  }

  .Wrap.project {
    padding-bottom: 6rem;
  }

  .CardProject {
    .details {
      .top {
        display: none;
      }

      .bottom {
        margin-bottom: 1rem;
      }
    }

    .detail {
      border-left: unset;
      border-top: 0;
      @include xl {
        border-left: solid 2px $black;

        &:first-of-type {
          border-left: none;
          padding-left: 0;
        }
      }

      &:first-of-type, &:nth-of-type(2) {
        border-bottom: 2px solid $black;
        @include xl {
          border-bottom: unset;
        }
      }
    }
  }

  .disclaimer {
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }

  .cta {
    display: flex;
    justify-content: center;
    padding: 4rem 6rem 0;
    // padding: 4rem 0 6rem;

    > a {
      width: 10rem;
    }
  }

  .cta-secondary {
    background-color: $white;
    display: flex;
    justify-content: center;

    > a {
      width: 26rem;
    }
  }

  .location {
    @include roundSvgDown('../../assets/ellipse-white.svg');
    height: 40vh;
    position: relative;

    .info {
      bottom: 20%;
      color: $white;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      right: 0;
      text-align: center;
      width: 10rem;
      z-index: 2;

      .Icon {
        font-size: 3rem;
      }

      .address {
        margin-top: 1rem;
      }
    }

    img {
      filter: brightness(0.4);
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .Sect {
    padding-top: 6rem;
  }

}
</style>
