import { isObjectOrArray } from 'views/utils'

export const uniqueBy = (array, key) => {
  const unique = {}

  array.forEach((item) => {
    if (unique[item[key]]) return

    unique[item[key]] = item
  })

  return Object.values(unique)
}

export const factory = factoryFunction => (_, getters) => (overrides = {}) => ({
  ...factoryFunction(getters),
  ...overrides,
})

export const hashPagination = ({ $limit, $skip }) => `${$limit || 20}|${$skip || 0}`
export const hashQuery = query => JSON.stringify(query)
export const omitPagination = ({ $skip, $limit, ...query }) => query

export const getterOptions = (getterKey, nullOption) => (_, getters) => [
  ...nullOption ? [nullOption] : [],
  ...getters[getterKey],
]

export const getterFinder = getterKey => (_, getters) => valueToFind =>
  getters[getterKey].find(({ value }) => valueToFind === value)

export const stateOptions = (valueKey = 'name', nullOption) => {
  const getValue = typeof valueKey === 'function'
    ? valueKey
    : record => record[valueKey]

  return state => [
    ...nullOption ? [nullOption] : [],
    ...Object
      .values(stateList(state))
      .map(record => [
        record.id,
        getValue(record),
      ]),
  ]
}

export const stateList = state => state.list || state

export const toActionName = name => name.split(/(?=[A-Z])/).join('_').toUpperCase()

function convertRecordDates(record) {
  if (!record) return record

  Object
    .keys(record)
    .forEach((key) => {
      if (!record[key]) return

      if ((key.endsWith('At') || key.endsWith('Date')) && !isObjectOrArray(record[key])) {
        record[key] = new Date(record[key])
      } else if (isObjectOrArray(record[key])) {
        if (Array.isArray(record[key])) {
          record[key].forEach(convertRecordDates)
        } else {
          convertRecordDates(record[key])
        }
      }
    })

  return record
}

// NOTE: mutates
export const parseObject = object => convertRecordDates(object)
