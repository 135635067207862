<template>
  <div
    class="MenuLink"
    :class="{ active: isActive }">
    <Route
      v-if="link.to"
      :key="link.label"
      :title="link.label"
      :to="link.to"
      @click.native="$emit('click')"
      @keypress.enter.native="$emit('click')">
      <Icon v-if="link.icon" :name="link.icon" />
      {{ link.label }}
    </Route>
    <span
      v-else
      :key="link.label"
      ref="dropdown"
      class="parent-link"
      :tabindex="index + 2"
      @click="isOpen = !isOpen"
      @keypress.enter="isOpen = !isOpen">
      {{ link.label }}
      <Icon v-if="link.children" :name="isOpen ? 'up' : 'down'" />
    </span>
    <transition name="slide-fade">
      <div v-if="isOpen" class="children" @mouseleave="closeChildren">
        <Route
          v-for="child in link.children"
          :key="child.label"
          class="child-link"
          :isExternal="child.isExternal"
          :title="child.label"
          :to="child.to"
          @click.native="closeChildren">
          {{ child.label }}
        </Route>
        <!-- <div key="close" class="child-link close">
          <Icon name="cross" /> Uždaryti
        </div> -->
      </div>
    </transition>
  </div>
</template>

<script>
import { actions, isObject } from '../../utils'

export default {
  props: {
    index: Number,
    link: Object,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    isActive() {
      if (this.link.hasIgnoredActive) return false
      if (this.isSameRoute(this.link)) return true
      return this.link.children && this.link.children.some(this.isSameRoute)
    },
  },
  methods: {
    checkClickLocation(event) {
      if (!this.$refs.dropdown || !this.isOpen) return

      if (!this.$refs.dropdown.contains(event.target)) {
        this.isOpen = false
      }
    },
    closeChildren() {
      this.isOpen = false
      this.$emit('click')
    },
    isSameRoute(link) {
      if (typeof link.to === 'string') {
        return link.to === this.$route.name
      }

      if (isObject(link.to)) {
        return link.to.name === this.$route.name
      }

      return false
    },
    ...actions(),
  },
  async beforeMount() {
    if (this.link.children) {
      this.$offEvents = await this.ON({
        click: this.checkClickLocation,
      })
    }
  },
  beforeDestroy() {
    this.$offEvents && this.$offEvents()
  },
}
</script>

<style lang="scss">
.MenuLink {
  // border-right: 1px solid $white;
  display: inline-block;
  font-size: 1rem;
  letter-spacing: 2.56px;
  line-height: 1.25rem;
  padding: 0 1rem;
  position: relative;
  text-transform: uppercase;
  @include md {
    font-size: $h6;
    padding: 0 0.5rem;
  }
  // @include lg {
  //   font-size: 0.5rem;
  //   padding: 0 0.5rem;
  // }
  @include xl {
    font-size: 0.85rem;
    padding: 0 1.4rem;

  }

  > a, span {
    border-bottom: 0;
    color: $white;
  }

  > .parent-link {
    cursor: pointer;

    > .Icon > i {
      vertical-align: bottom;
    }
  }

  > .children {
    border-radius: $radius-semi;
    color: $white-ter;
    display: flex;
    flex-direction: column;
    left: -4.5rem;
    max-width: 100vw;
    overflow: hidden;
    // padding: 0.5rem 1.5rem;
    position: absolute;
    top: 2.5rem;
    width: 20rem;
    z-index: 1;

    > .child-link {
      background: $blue-dark;
      color: $white-ter;
      font-weight: $regular;
      line-height: 3rem;
      padding: 2rem;
      text-align: center;
      text-transform: uppercase;
      @include lg {
        line-height: inherit;
      }

      &:hover {
        background: $white;
        color: $blue;
      }

      // &.close {
      //   border-top: 1px solid;
      //   cursor: pointer;
      //   @include lg {
      //     padding-top: 0.75rem;
      //   }

      //   .Icon {
      //     color: $main;
      //     vertical-align: middle;
      //   }
      // }
    }
  }

  &:hover {
    > a {
      color: $blue;
    }
  }

  // &.active {
  //   > a, > span {
  //     border-bottom: 2px solid $white;
  //   }
  // }
}

#mobile-menu .MenuLink {
  border: 0;
  border-bottom: 1px solid $white;
  display: block;
  font-size: $h4;
  font-weight: $regular;
  line-height: 4rem;
  width: 100%;

  > .children {
    top: unset;
  }

  &.last {
    border: 0;
  }
}
</style>
