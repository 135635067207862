<template>
  <RadioTabs
    class="FieldSkipAdvance"
    :disabled="disabled"
    label="Mokėjimas"
    :value="value"
    @update:value="$up('value', $event)">
    <div
      slot="false"
      data-cy="pay-ADVANCE"
      :label="`Avansas (${advanceAmountDefault}%)`"
      :value="false">
      {{ `Renkuosi mokėti ${advanceAmountDefault}% avansinį mokestį dabar ir galutinį mokestį vėliau` }}
    </div>
    <div
      slot="true"
      data-cy="pay-ONCE"
      label="Pilna kaina"
      :value="true">
      Renkuosi iškart mokėti pilną sumą
    </div>
  </RadioTabs>
</template>

<script>
import RadioTabs from 'components/fields/RadioTabs'

export default {
  components: { RadioTabs },
  props: {
    value: Boolean,
    disabled: Boolean,
    advanceAmountDefault: Number,
  },
}
</script>

<style lang="scss">
.FieldSkipAdvance {
  margin-bottom: $grid-unit;
}
</style>
