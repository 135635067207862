<template>
  <CardDocument
    class="Contract"
    :data-cy="contractCy"
    :document="contract"
    :title="getContractTypeLabel(contract.type)">
    <div slot="status">
      <Set v-if="contract.signedAt" class="lg success" name="Pasirašyta">
        {{ contract.signedAt | date }}
      </Set>
      <Set v-else-if="contract.cancelledAt" class="lg danger" name="Būsena">
        Atšaukta
      </Set>
      <Set v-else class="lg danger" name="Būsena">
        Nepasirašyta
      </Set>
      <Btn
        v-if="!contract.signedAt && !contract.cancelledAt && hasManualSigning"
        class="buttonSign success sm"
        data-cy="sign"
        :isLoading="isSigning"
        @click="PROMPT({
          confirm: sign,
          text: 'Ar tikrai norite pasirašyti šią sutartį?',
        })">
        Pasirašyti
      </Btn>
    </div>
  </CardDocument>
</template>

<script>
import { actions, camelCase, getters } from '@/views/utils'
import CardDocument from './CardDocument'
import Seen from '../mixins/Seen'
import Set from './Set'

export default {
  mixins: [Seen('contract', 'CONTRACT', true, 2000)],
  components: { CardDocument, Set },
  props: {
    contract: Object,
    project: Object,
    reservation: Object,
  },
  data() {
    return {
      isSigning: false,
    }
  },
  computed: {
    contractCy() {
      const { type, signedAt } = this.contract
      const transformedType = camelCase(type)
      return `contract${transformedType}${signedAt ? 'Signed' : ''}`
    },
    hasManualSigning() {
      return this.doesStepBranchSupportManualSigning(this.project.stepBranch)
    },
    ...getters('getContractTypeLabel', 'doesStepBranchSupportManualSigning'),
  },
  methods: {
    async sign() {
      await this.$wrap(
        this.PATCH_CONTRACT({
          id: this.contract.id,
          signedAt: new Date(),
        }),
        'isSigning',
        'Sutartis pasirašyta',
      )
    },
    ...actions(
      'PATCH_CONTRACT',
      'PROMPT',
    ),
  },
}
</script>

<style lang="scss">
.Contract {
  padding: 1rem 2rem;

  .buttonSign {
    margin: 0.5rem 0;
  }
}
</style>
