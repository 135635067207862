<template>
  <div id="FormContactNew">
    <Wrap class="md">
      <h2>Užsisakykite nemokamą konsultaciją</h2>

      <FormValidation v-if="!hasSent" @submit="send">
        <Row>
          <Column :md="6">
            <Field
              v-if="fields.includes('firstName')"
              autocomplete="given-name"
              label="Vardas"
              :maxlength="100"
              :minlength="2"
              required
              :value.sync="contact.firstName" />
          </Column>
          <Column :md="6">
            <Field
              v-if="fields.includes('lastName')"
              autocomplete="family-name"
              label="Pavardė"
              :maxlength="100"
              :minlength="2"
              required
              :value.sync="contact.lastName" />
          </Column>
        </Row>
        <Row>
          <Column :md="6">
            <Field
              v-if="fields.includes('email')"
              autocomplete="email"
              label="El. paštas"
              :maxlength="100"
              :minlength="5"
              required
              rules="email"
              type="email"
              :value.sync="contact.email" />
          </Column>
          <Column :md="6">
            <Field
              v-if="fields.includes('phone')"
              autocomplete="phone-number"
              label="Telefono numeris"
              :maxlength="16"
              :minlength="6"
              required
              rules="phone"
              :value.sync="contact.phone" />
          </Column>
        </Row>
        <Row>
          <Column :md="6">
            <Field
              v-if="fields.includes('powerUsage')"
              name="powerUsage"
              placeholder="Suvartojamas elektros kiekis per mėnesį (kWh)"
              type="text"
              :value.sync="contact.powerUsage" />
          </Column>
          <Column :md="6">
            <Field
              v-if="fields.includes('address')"
              autocomplete="address"
              :maxlength="200"
              :minlength="4"
              placeholder="Miestas, gatvė, namo numeris"
              validation="address"
              :value.sync="contact.address" />
          </Column>
        </Row>
        <Field
          v-if="fields.includes('comment')"
          label="Žinutė"
          :maxlength="1000"
          placeholder="..."
          type="textarea"
          :value.sync="contact.comment" />
        <div class="checkboxes">
          <FieldConsent :value.sync="contact.hasAcceptedPolicy" />
          <FieldSubscribe :value.sync="contact.hasSubscribed" />
        </div>
        <div class="submitContainer">
          <Btn class="Btn arrow" :isLoading="isSending" type="submit">
            Siųsti
          </Btn>
        </div>
      </FormValidation>
      <Notice v-if="hasSent && message" :class="message.theme">
        {{ message.text }}
      </Notice>
    </Wrap>
  </div>
</template>

<script>
import { actions, getters } from '../../utils'
import FieldConsent from '../FieldConsent'
import FieldSubscribe from '../FieldSubscribe'
import Message from '../../mixins/Message'

export default {
  mixins: [Message],
  components: { FieldConsent, FieldSubscribe },
  props: {
    eventLabel: String,
    fields: {
      type: Array,
      default: () => [
        'firstName',
        'lastName',
        'email',
        'phone',
        'powerUsage',
        'address',
        'comment',
      ],
    },
  },
  data() {
    return {
      contact: this.$store.getters.contactFactory(),
      isSending: false,
      hasSent: false,
    }
  },
  computed: getters('referrers'),
  methods: {
    async send() {
      try {
        this.isSending = true
        await this.CREATE_CONTACT({
          metaReferrers: this.referrers,
          ...this.contact,
        })
        this.hasSent = true
        this.success('Dėkojame! Susisieksime su jumis artimiausiu metu!')

        // this.$ga.event('Contact', 'submit', this.eventLabel)
        // this.$analytics.fbq.event('Lead', this.eventLabel && { label: this.eventLabel })

        // if (this.contact.hasSubscribed) {
        //   this.$ga.event('Newsletter', 'subscribe')
        // }
      } finally {
        this.isSending = false
      }
    },
    ...actions('CREATE_CONTACT'),
  },
  watch: {
    isAuth: {
      immediate: true,
      handler(isAuth) {
        if (!isAuth) return

        Object
          .keys(this.contact)
          .filter(key => key !== 'hasSubscribed')
          .forEach((key) => {
            if (this.authUser[key]) this.contact[key] = this.authUser[key]
          })
      },
    },
  },
}
</script>

<style lang="scss">
#FormContactNew {
  margin: 1rem 0 0;
  @include lg {
    margin: 4rem 0;
  }

  // > .Wrap {
  //   padding: 0;
  // }

  h2 {
    margin-bottom: 1.5rem;
  }

  p {
    line-height: 1.35rem;
    margin: 1rem 0;
  }

  a {
    border-color: $white;
    // color: $white;
    text-decoration: underline;
  }

  form {
    @include lg {
      margin-top: 3rem;
    }

    .Field-cont {
      color: $text-color-mid;
      margin-bottom: 0.15rem;

      // input {}
      // textarea {
      //   text-align: center;
      //   text-align-last: center;
      //   @include md {
      //     text-align: left;
      //     text-align-last: left;
      //   }
      // }

      textarea {
        min-height: 80px;
      }

      button {
        padding: 0.25rem 0;
        width: 10rem;
        @include lg {
          margin-top: 1rem;
          padding: 0.5rem 0;
        }
      }

      // .Field.type-checkbox label {
      //   color: $white;
      //   line-height: 1.25rem;
      // }

      // .Field-errors {
      //   background: $main;
      //   border-radius: $radius;
      //   color: $white;
      //   padding: 0.5rem;
      // }

      .checkboxes {
        margin-top: 0.5rem;
        text-align: left;
        @include md {
          margin-top: 0;
        }

        .Field-errors {
          margin-bottom: 1rem;
        }
      }

    }

    .submitContainer {
      display: flex;
      justify-content: center;

      .Btn {
        max-width: 20rem;
        @include lg {
          max-width: 12rem;
        }
      }
    }
  }

  .Notice {
    margin-top: 2rem;
  }

  .Row {
    margin: 0 -0.25rem;

    .Column {
      padding: 0 0.25rem;
      @include lg {
        padding: 0.25rem;
      }
    }
  }
}
</style>
