<template>
  <ProgressBar
    class="CardReservationProgressLegacy"
    :class="stepWidth"
    :steps="progressionSteps">
    <div v-if="contractReservation" slot="step-2">
      <Btn
        class="sm ib"
        @click="downloadReservationContract">
        Atsisiųsti sutartį
      </Btn>
    </div>
    <div
      v-if="contractMain ||
        (!reservation.isCancelled &&
          paymentReservation &&
          paymentReservation.paidAt)"
      slot="step-3">
      <template v-if="contractMain">
        <ReservationCapacityChange
          v-if="isBetweenReservationAndAdvance"
          :reservation="reservation" />
        <Btn
          v-if="!reservation.isCancelled && contractMain.isHidden"
          class="sm ib"
          data-cy="sendMainContract"
          :isLoading="isSendingMainContract"
          @click="sendMainContract">
          Išsiųsti sutartį
        </Btn>
        <Btn
          class="sm ib"
          :isLoading="isGeneratingMainContract"
          @click="downloadMainContract">
          Atsisiųsti sutartį
        </Btn>
      </template>
      <Btn
        v-else-if="!reservation.isCancelled"
        class="sm ib"
        data-cy="createMainContract"
        :isLoading="isGeneratingMainContract"
        @click="createMainContract">
        Sukurti sutartį
      </Btn>
    </div>
    <div slot="step-4">
      <Btn
        v-if="!paymentMain &&
          contractMain &&
          !reservation.isCancelled"
        class="sm ib"
        data-cy="createMainPayment"
        :isLoading="isCreatingMainPayment"
        @click="createMainPayment">
        Sukurti mokėjimą
      </Btn>
      <Btn
        v-if="contractTransferAcceptance"
        class="sm ib"
        @click="downloadTransferAcceptanceContract">
        Atsisiųsti aktą
      </Btn>
    </div>
    <div v-if="paymentMain && !paymentMaintenance" slot="step-5">
      <div v-if="userStepPaymentMaintenance">
        {{ userStepPaymentMaintenance.executesAt | date }}
      </div>
      <div v-else-if="reservation.isReserved">
        <Btn
          class="sm ib"
          data-cy="createMaintenancePayment"
          :isLoading="isCreatingMaintenancePayment"
          @click="createMaintenancePayment">
          <template v-if="reservation.yearsFreeMaintenance">
            Už {{ reservation.yearsFreeMaintenance }} metų
          </template>
          Sukurti mokėjimą
        </Btn>
        <Btn
          v-if="!reservation.yearsFreeMaintenance"
          class="sm ib"
          :isLoading="isCreatingMaintenancePaymentPreview"
          @click="createMaintenancePaymentPreview">
          <span v-if="maintenancePaymentPreviewAmount">
            {{ maintenancePaymentPreviewAmount }} €
          </span>
          <Icon v-else name="eye" />
        </Btn>
      </div>
    </div>
  </ProgressBar>
</template>

<script>
import { CONTRACT_TYPES, PAYMENT_TYPES } from '@/store/consts'
import { actions, capitalize, getters } from 'views/utils'
import { last, pipe, pluck, prop, propEq, sortBy } from 'rambda'
import { BRANCH_NAMES } from '@support/consts'
import ProgressBar from 'components/admin/ProgressBar'
import ReservationCapacityChange from './ReservationCapacityChange'

export default {
  components: {
    ProgressBar,
    ReservationCapacityChange,
  },
  props: {
    paymentsMaintenance: Array,
    reservation: Object,
  },
  data() {
    return {
      isCreatingMainPayment: false,
      isCreatingMaintenancePayment: false,
      isCreatingMaintenancePaymentPreview: false,
      isGeneratingMainContract: false,
      isSendingMainContract: false,
      maintenancePaymentPreviewAmount: null,
    }
  },
  computed: {
    stepWidth() {
      return `steps-count-${this.progressionSteps.length}`
    },
    isBetweenReservationAndAdvance() {
      return this.paymentReservation &&
        !this.contractMain.signedAt &&
        !this.contractMain.cancelledAt
    },
    contractMain() {
      return this.queryOne('contract', {
        reservationId: this.reservation.id,
        type: CONTRACT_TYPES.MAIN,
      })
    },
    contractTransferAcceptance() {
      return this.queryOne('contract', {
        reservationId: this.reservation.id,
        type: CONTRACT_TYPES.TRANSFER_ACCEPTANCE,
      })
    },
    contractReservation() {
      return this.queryOne('contract', {
        reservationId: this.reservation.id,
        type: CONTRACT_TYPES.RESERVATION,
      })
    },
    paymentAdvance() {
      return this.queryOne('payment', {
        reservationId: this.reservation.id,
        type: PAYMENT_TYPES.ADVANCE,
      })
    },
    paymentMaintenance() {
      if (!this.paymentsMaintenance) return null

      return pipe(
        sortBy(prop('id')),
        last,
      )(this.paymentsMaintenance)
    },
    paymentReservation() {
      return this.queryOne('payment', {
        reservationId: this.reservation.id,
        type: PAYMENT_TYPES.RESERVATION,
      })
    },
    paymentMain() {
      return this.queryOne('payment', {
        reservationId: this.reservation.id,
        type: PAYMENT_TYPES.MAIN,
      })
    },
    progressionSteps() {
      const delayedMaintenanceFee = (() => {
        const hasDelayedInitialMaintenanceFee =
          this.userStepPaymentMaintenance &&
          !this.paymentMaintenance

        return hasDelayedInitialMaintenanceFee
          ? { theme: 'info' }
          : {}
      })()

      return [
        {
          name: 'Rezervavo',
          theme: 'success',
        },
        // this.getThemeAndName(PAYMENT_TYPES.RESERVATION, 'rezervacija'),
        // this.getThemeAndName(PAYMENT_TYPES.ADVANCE, 'avansas'),
        // this.getThemeAndName(PAYMENT_TYPES.MAIN, 'likutis'),
        ...this.progressionStepsByStepBranch,
        {
          ...this.getThemeAndName(PAYMENT_TYPES.MAINTENANCE, 'priežiūros mok.'),
          ...delayedMaintenanceFee,
        },
      ]
    },
    progressionStepsByStepBranch() {
      if (!this.project) return []

      const steps2024 = [
        this.getThemeAndNameContract(CONTRACT_TYPES.MAIN, 'sutartis'),
        this.getThemeAndName(PAYMENT_TYPES.ADVANCE, 'avansas'),
        this.getThemeAndNameContract(CONTRACT_TYPES.MAIN, 'perdavimo akt.'),
        this.getThemeAndName(PAYMENT_TYPES.MAIN, 'likutis'),
      ]

      // For all projects using STEP_BRACH < 2024
      const stepsLegacy = [
        this.getThemeAndName(PAYMENT_TYPES.RESERVATION, 'rezervacija'),
        this.getThemeAndName(PAYMENT_TYPES.ADVANCE, 'avansas'),
        this.getThemeAndName(PAYMENT_TYPES.MAIN, 'likutis'),
      ]

      return {
        [BRANCH_NAMES[2020]]: stepsLegacy,
        [BRANCH_NAMES[2022]]: stepsLegacy,
        [BRANCH_NAMES['2022_V2']]: stepsLegacy,
        [BRANCH_NAMES[2024]]: steps2024,
      }[this.project.stepBranch]
    },
    project() {
      return this.queryOne('project', this.reservation.projectId)
    },
    stepPaymentMaintenance() {
      const outcome = this
        .getMatchingStepOutcome({
          action: 'created',
          type: 'payment',
          resourceType: PAYMENT_TYPES.MAINTENANCE,
          resourceId: null,
        }, this.project, this.reservation)

      const stepPaymentId = (outcome || {}).resourceId

      if (!stepPaymentId) return

      return this.queryOne('stepPayment', stepPaymentId)
    },
    stepPayments() {
      return this.query('stepPayment')
    },
    stepContracts() {
      return this.query('stepContract')
    },
    userStepsPaymentMaintenance() {
      return this.query('userStep', {
        reservationId: this.reservation.id,
        resourceType: PAYMENT_TYPES.MAINTENANCE,
        $sort: { executesAt: -1 },
      })
    },
    userStepPaymentMaintenance() {
      return this.userStepsPaymentMaintenance[0]
    },
    ...getters(
      'getMatchingStepOutcome',
      'getPaymentStatus',
      'getContractStatus',
    ),
  },
  methods: {
    async createMainContract() {
      const stepContractMain = (() => {
        const outcome = this
          .getMatchingStepOutcome({
            action: 'created',
            type: 'contract',
            resourceType: CONTRACT_TYPES.MAIN,
            resourceId: null,
          }, this.project, this.reservation)

        const stepContractId = (outcome || {}).resourceId

        if (!stepContractId) return

        return this.queryOne('stepContract', stepContractId)
      })()

      if (!stepContractMain) {
        const errorMessage = 'Nepavyksta rasti sutarties šablono'
        this.ERROR(errorMessage)
        throw new Error(errorMessage)
      }

      await this.$wrap(() => this.CREATE_CONTRACT({
        isHidden: true,
        reservationId: this.reservation.id,
        stepContractId: stepContractMain.id,
      }), 'isGeneratingMainContract')
    },
    async createMaintenancePayment() {
      if (!this.stepPaymentMaintenance) {
        const errorMessage = 'Nepavyksta rasti priežiūros mokėjimo šablono'
        this.ERROR(errorMessage)
        throw new Error(errorMessage)
      }

      const payment = {
        reservationId: this.reservation.id,
        stepPaymentId: this.stepPaymentMaintenance.id,
      }

      this.$wrap(async () => {
        if (!this.reservation.yearsFreeMaintenance) {
          return await this.CREATE_PAYMENT(payment)
        }

        const executesAt = new Date()
        executesAt.setFullYear(executesAt.getFullYear() + this.reservation.yearsFreeMaintenance)

        await this.CREATE_USER_STEP({
          action: 'created',
          type: 'payment',
          resourceType: 'MAINTENANCE',
          executesAt,
          ...payment,
        })
      }, 'isCreatingMaintenancePayment')
    },
    async createMaintenancePaymentPreview() {
      if (!this.stepPaymentMaintenance) {
        const errorMessage = 'Nepavyksta rasti priežiūros mokėjimo šablono'
        this.ERROR(errorMessage)
        throw new Error(errorMessage)
      }

      const payment = {
        reservationId: this.reservation.id,
        stepPaymentId: this.stepPaymentMaintenance.id,
      }

      const result = await this.$wrap(() =>
        this.CREATE_RAW_PAYMENT({
          ...payment,
          $isDryCall: true,
        }), 'isCreatingMaintenancePaymentPreview')

      this.maintenancePaymentPreviewAmount = result.amountTotal
    },
    async createMainPayment() {
      const stepPaymentMain = (() => {
        const outcome = this
          .getMatchingStepOutcome({
            action: 'created',
            type: 'payment',
            resourceType: PAYMENT_TYPES.MAIN,
            resourceId: null,
          }, this.project, this.reservation)

        const stepPaymentId = (outcome || {}).resourceId

        if (!stepPaymentId) return

        return this.queryOne('stepPayment', stepPaymentId)
      })()

      if (!stepPaymentMain) {
        const errorMessage = 'Nepavyksta rasti galutinio mokėjimo šablono'
        this.ERROR(errorMessage)
        throw new Error(errorMessage)
      }

      this.$wrap(async () => {
        await this.CREATE_PAYMENT({
          reservationId: this.reservation.id,
          stepPaymentId: stepPaymentMain.id,
        })
      }, 'isCreatingMainPayment')
    },
    async downloadMainContract() {
      await this.DOWNLOAD_FILE(this.contractMain)
    },
    async downloadTransferAcceptanceContract() {
      await this.DOWNLOAD_FILE(this.contractTransferAcceptance)
    },
    async downloadReservationContract() {
      await this.DOWNLOAD_FILE(this.contractReservation)
    },
    getThemeAndName(stepPaymentType, paymentNameSuffix) {
      const stepPayments = this.stepPayments
        .filter(propEq('type', stepPaymentType))
      const stepPaymentIds = pluck('id', stepPayments)

      const payments = this.query('payment', {
        reservationId: this.reservation.id,
        stepPaymentId: {
          $in: stepPaymentIds,
        },
        $limit: stepPaymentIds.length,
        $sort: { id: -1 },
      })

      const payment = payments[payments.length - 1]

      if (!payment) {
        const name = `${capitalize(paymentNameSuffix)}`

        if (
          stepPaymentType === PAYMENT_TYPES.RESERVATION &&
          this.reservation.hasSkippedReservation
        ) {
          return {
            name,
            theme: 'info',
            sub: 'Praleista',
          }
        }

        return {
          name,
          theme: null,
        }
      }

      const paymentStatus = this.getPaymentStatus(payment)
      const label = paymentNameSuffix.endsWith('a')
        ? paymentStatus.label.slice(0, -1)
        : paymentStatus.label

      const paidOrRefuned = payment.refundedAt || payment.paidAt

      return {
        theme: paymentStatus.theme,
        name: `${label} ${paymentNameSuffix}`,
        sub: paidOrRefuned
          ? this.$options.filters.dateTime(paidOrRefuned)
          : `iki ${this.$options.filters.dateTime(payment.deadlineAt)}`,
      }
    },

    getThemeAndNameContract(stepContractType, contractNameSuffix) {
      const stepContracts = this.stepContracts
        .filter(propEq('type', stepContractType))
      const stepContractIds = pluck('id', stepContracts)

      const contracts = this.query('contract', {
        reservationId: this.reservation.id,
        stepContractId: {
          $in: stepContractIds,
        },
        $limit: stepContractIds.length,
        $sort: { id: -1 },
      })

      const contract = contracts[contracts.length - 1]

      if (!contract) {
        const name = `${capitalize(contractNameSuffix)}`

        return {
          name,
          theme: null,
        }
      }

      const contractStatus = this.getContractStatus(contract)
      const label = contractNameSuffix.endsWith('a')
        ? contractStatus.label.slice(0, -1)
        : contractStatus.label

      return {
        theme: contractStatus.theme,
        name: `${label} ${contractNameSuffix}`,
        sub: contract.signedAt
          ? this.$options.filters.dateTime(contract.signedAt)
          : this.$options.filters.dateTime(contract.createdAt),
      }
    },

    async sendMainContract() {
      this.$wrap(() => this.PATCH_CONTRACT({
        id: this.contractMain.id,
        isHidden: false,
      }), 'isSendingMainContract')
    },
    ...actions(
      'CREATE_CONTRACT',
      'CREATE_PAYMENT',
      'CREATE_RAW_PAYMENT',
      'CREATE_USER_STEP',
      'DOWNLOAD_FILE',
      'PATCH_CONTRACT',
    ),
  },
}
</script>

<style lang="scss">
.CardReservationProgressLegacy {
  &.steps-count-5 {
    .ProgressBarStep {
      width: calc(100% / 5);
    }
  }

  &.steps-count-6 {
    .ProgressBarStep {
      width: calc(100% / 6);
    }
  }
}
</style>
