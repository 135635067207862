<template>
  <div class="StepField">
    <Btn class="inv" icon="edit" @click="toggle">
      {{ label }}
    </Btn>
    <div v-if="isOpen" class="StepField-form">
      <slot />
    </div>
  </div>
</template>

<script>
import { actions } from '@/views/utils'

export default {
  props: {
    label: String,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    checkClickLocation(event) {
      if (!this.isOpen) return

      if (!this.$el.contains(event.target)) {
        this.isOpen = false
      }
    },
    toggle() {
      this.isOpen = !this.isOpen
    },
    ...actions(),
  },
  async beforeMount() {
    this.$offEvents = await this.ON({
      click: this.checkClickLocation,
    })
  },
  beforeDestroy() {
    this.$offEvents && this.$offEvents()
  },
}
</script>

<style lang="scss">
$_width: 30rem;
$_height: 20rem;

.StepField {
  position: relative;

  .StepField-form {
    background: $body-bg;
    border: 1px solid $border-color;
    height: $_height;
    overflow: auto;
    padding: $grid-unit;
    position: absolute;
    width: $_width;
    z-index: 1;
  }
}
</style>
