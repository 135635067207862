<template>
  <div class="CardProject">
    <div class="details wrap">
      <div class="top">
        <div class="left">
          <div class="title">
            {{ title }}
            <!-- Saulės parkas „{{ project.name }}“ -->
          </div>
          <div class="location">
            <Icon name="pin" /> {{ (project.locationShort || '').toUpperCase() }}
          </div>
        </div>
        <div class="right">
          <div class="cta">
            <Route class="Btn arrow minimalistic" :to="{ name: 'ProjectDetails', params: { slug: project.slug } }">
              Daugiau informacijos
            </Route>
          </div>
          <div v-if="isReservationsActive" class="cta">
            <Route class="Btn arrow" data-cy="reserve" :to="{ name: reserveRoute, params: { id: project.id } }">
              Įsigyti
            </Route>
          </div>
        </div>
      </div>
      <Row class="bottom nowrap">
        <Column
          v-for="(detail, index) in details"
          :key="index"
          class="detail"
          :xl="details.length === 4 ? 3 : 6"
          :xs="6">
          <div class="content">
            <div class="label">
              {{ detail.label }}
            </div>
            <Row v-if="detail.valueBefore" class="value nowrap">
              <!-- eslint-disable-next-line vue/attributes-order -->
              <Column :md="6" :lg="12" :xxl="6">
                <div>
                  {{ detail.value }}
                </div>
              </Column>
              <!-- eslint-disable-next-line vue/attributes-order -->
              <Column :md="6" :lg="12" :xxl="6">
                <span class="cross-out">
                  {{ detail.valueBefore }}
                </span>
              </Column>
            </Row>
            <div v-else>
              <div class="value">
                {{ detail.value }}
              </div>
            </div>
          </div>
        </Column>
      </Row>
    </div>
    <Route class="coverLinkWrap" :to="coverLink">
      <div class="preview" :class="{ grayed: messageOverlay }">
        <div v-if="project.priceBefore" class="discountBlob">
          <div class="discountTextContainer">
            <div class="discountText">
              <span class="prefix">DABAR TIK</span>
              <br>
              <span class="suffix">{{ project.price }} Eur/kW</span>
            </div>
          </div>
        </div>
        <div class="sliderContainer">
          <CapacitySlider
            :sliderInterval="0.1"
            :total="project.capacity"
            :used="project.capacityUsed || 0" />
        </div>
        <Pic
          :src="project.imageUrl || 'https://sig-web-uploads.s3.eu-central-1.amazonaws.com/vievis_rsz_7e90f15d5c.jpeg'" />
        <div v-if="messageOverlay" class="inactiveOverlay" />
        <div v-if="messageOverlay" class="messageOverlay">
          <span>{{ messageOverlay }}</span>
        </div>
        <div v-if="displayNameOverlay" class="nameOverlay">
          <span>{{ project.name }}</span>
        </div>
      </div>
    </Route>
    <div v-if="doShowReservations && reservations.length" class="reservations">
      <ProjectReservation
        v-for="reservation in reservations"
        :key="reservation.id"
        :reservation="reservation" />
    </div>
  </div>
</template>

<script>
import CapacitySlider from './CapacitySlider'
import ProjectReservation from 'views/components/ProjectReservation'
import { getters } from 'views/utils'

export default {
  components: { CapacitySlider, ProjectReservation },
  props: {
    project: Object,
    doShowReservations: {
      type: Boolean,
      default: false,
    },
    displayNameOverlay: {
      type: Boolean,
      default: false,
    },
    coverLinkProp: {
      type: Object,
    },
  },
  computed: {
    coverLink() {
      return this.coverLinkProp ||
        {
          name: 'ProjectDetails',
          params: { slug: this.project.slug },
        }
    },
    title() {
      return this.project.name.startsWith('Saulės Parkas')
        ? this.project.name
        : `Saulės Parkas „${this.project.name}“`
    },
    isReservationsActive() {
      return this.project.status === 'ACTIVE'
    },
    messageOverlay() {
      const messagesByStatus = {
        PLANNING: 'REZERVACIJA NETRUKUS',
        ACTIVE: null,
        FUNDED: null,
        CONSTRUCTION: 'REZERVACIJA BAIGTA',
        BUILT: 'PARKAS JAU VEIKIA',
      }

      return messagesByStatus[this.project.status]
    },
    startDate() {
      // const dateString = isStarted
      //   ? this.project.startedDate
      //   : this.project.startingDate

      const startDate = new Date(this.project.constructionDate)
      const isStarted = startDate <= new Date()
      const month = `0${startDate.getMonth() + 1}`.slice(-2)

      return {
        label: isStarted
          ? 'Parkas pradėjo veikti'
          : 'Planuojama parko paleidimo data',
        value: `${startDate.getFullYear()} ${month}`,
      }
    },
    details() {
      return [
        {
          label: 'Galia:',
          value: `${this.project.capacity} kW`,
        },
        this.startDate,
        ...this.isReservationsActive
          ? [
            {
              label: 'Pirkimo kaina:',
              value: `${this.project.price} Eur/kW`,
              valueBefore: this.project.priceBefore ? `${this.project.priceBefore} Eur/kW` : null,
            },
            {
              label: 'Metinė priežiūros kaina:',
              value: `${this.project.maintenanceAnnual} Eur/kW`,
              valueBefore: this.project.maintenanceAnnualBefore ? `${this.project.maintenanceAnnualBefore} Eur/kW` : null,
            },
          ] : [],
      ]
    },
    reserveRoute() {
      return this.isAuth
        ? 'SelfReservationAdd'
        : 'SelfReservationAddGuest'
    },
    reservations() {
      return this.query('reservation', {
        projectId: this.project.id,
        userId: this.authUser.id,
      })
    },
    reservationsActive() {
      return this.reservations
        .filter(reservation => reservation.isReserved)
    },
    ...getters(),
  },
  methods: {
    async submit() {
      await this.PATCH_USER({
        id: this.authUser.id,
        hasSubscribed: true,
      })

      this.SUCCESS('Nuo šiol gausite naujienlaiškius')
    },
  },
}
</script>

<style lang="scss">
.CardProject {
  background: $white;
  border-radius: $radius 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .cross-out {
    position: relative;
    text-decoration: none;

    &::before {
      background: $red;
      border-radius: 0.1rem;
      content: '';
      display: block;
      height: 0.2rem;
      left: -5%;
      opacity: 0.8;
      position: absolute;
      top: 50%;
      transform: rotate(-5deg);
      white-space: nowrap;
      width: 100%;
    }
  }

  .coverLinkWrap {
    border-bottom: unset;
  }

  .discountBlob {
    align-content: center;
    background: $black;
    background-image: linear-gradient(120deg, $info 0%, $info-dark 100%);
    border-radius: $radius-round 0 0 $radius-round;
    display: flex;
    padding: 0 2rem 0 3rem;
    position: absolute;
    right: 0;
    text-align: right;
    top: 15%;
    z-index: 1;

    .discountTextContainer {
      padding: 1rem 0;

      .discountText {
        color: $white;
        font-weight: $bold;

        .prefix {
          font-size: $h4;
          @include lg {
            font-size: 2rem;
          }
        }

        .suffix {
          font-size: $h25;
          @include lg {
            font-size: $h2;
          }
        }
      }
    }
  }

  .wrap {
    padding: 2rem 3rem 0;
  }

  .details {
    .top {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 1rem;
      min-height: 12rem;
      @include lg {
        min-height: 6rem;
      }
      // @include xl {
      //   flex-direction: row;
      // }

      .left {
        font-size: 1rem;
        font-weight: $bold;

        .title {
          font-size: $h4;
          white-space: normal;
        }

        .location {
          color: $blue;
          letter-spacing: 0.2rem;
          margin-top: 0.5rem;
        }
      }

      .right {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        @include md {
          flex-direction: row;

          .cta {
            margin-right: 1rem;
          }
        }
        @include xl {
          .cta {
            white-space: nowrap;
          }
        }

        .text {
          white-space: nowrap;
        }

        .cta {
          margin-top: 1rem;
          white-space: normal;

          &:last-of-type {
            margin-right: 0;
          }
        }

        .reserve {
          white-space: nowrap;

          .circle {
            margin-left: 1rem;
          }
        }
      }
    }

    .bottom {
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
      width: 100%;

      .detail {
        align-items: center;
        border-left: solid 2px $black;
        border-top: solid 2px $black;
        display: flex;
        // flex: 1 1 0;
        padding: 1rem 0 1rem 1rem;
        white-space: normal;
        @include xl {
          &:nth-of-type(odd) {
            border-left: solid 2px $black;
            padding-left: 1rem;
          }

          &:first-of-type {
            border-left: none;
            padding-left: 0;
          }
        }

        > .content {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;
          width: 100%;
        }

        .label {
          font-size: 14px;
          margin-bottom: 0.5rem;
          opacity: 0.5;
        }

        .value {
          font-size: 1.125rem;
          font-weight: $bold;
        }

        &:nth-of-type(odd) {
          border-left: none;
          padding: 1rem 1rem 1rem 0;
        }
      }
    }
  }

  .preview {
    border-radius: $radius 0;
    height: 25rem;
    // opacity: 0.66;
    overflow: hidden;
    position: relative;

    &::before {
      background: linear-gradient(to top, rgba($black, 0.7), rgba($black, 0.1), transparent, transparent, transparent);
      bottom: 0;
      content: " ";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: background-color 0.2s linear;
      z-index: 1;
    }

    .inactiveOverlay {
      background: $black;
      height: 100%;
      left: 0;
      opacity: 0.5;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    .messageOverlay {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;

      > span {
        color: $white;
        font-size: $h3;
        font-weight: $bold;
        @include md {
          font-size: $h2;
        }
        // @include xl {
        //   font-size: $h1;
        // }
      }
    }

    .nameOverlay {
      margin: 2rem 5rem;
      position: absolute;
      top: 0;
      z-index: 2;

      > span {
        color: $white;
        font-size: $h4;
        font-weight: $bold;
        @include md {
          font-size: $h2;
        }
      }
    }

    .sliderContainer {
      bottom: 1rem;
      padding: 0 2rem 0 1rem;
      position: absolute;
      width: 100%;
      z-index: 1;
      @include lg {
        padding: 0 5rem;
      }

      > .CapacitySlider {
        // bottom: 0.5rem;
        // margin: 0 5%;
        // position: absolute;
        // width: 90%;
      }
    }

    img {
      filter: brightness(0.8);
      height: 100%;
      object-fit: cover;
      transition: filter 0.2s linear;
      width: 100%;
    }

    &.grayed {
      img {
        filter: grayscale(80%);
      }
    }

    &:hover {
      &::before {
        background: linear-gradient(to top, rgba($black, 0.9), rgba($black, 0.2), transparent, transparent, transparent);
        bottom: 0;
        content: " ";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: background-color 0.2s linear;
        z-index: 1;
      }

      img {
        filter: brightness(1);
        transition: filter 0.2s linear;
      }
    }
  }

  .reservations {
    // background: $white;
    // border: 1px solid $black;
    padding: 1rem;

    .ProjectReservation {
      border-bottom: 1px solid $black;
    }
  }

}
</style>
