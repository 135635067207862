<template>
  <div class="Footer" :class="{ extended: !$slots.default }">
    <Wrap class="xxl">
      <div class="top">
        <Row class="peak">
          <Column :md="2" :xs="8">
            <MainLogo />
          </Column>
          <Column v-if="isBigFooter" class="footerMenu" :md="9">
            <MenuLink
              v-for="(menuItem, index) in menuItems"
              :key="index"
              :link="menuItem" />
          </Column>
          <Column class="social" :md="1" :xs="4">
            <a class="linkedin" href="https://linkedin.com/company/saules-bendruomene" target="_blank">
              <Icon name="linkedin" />
            </a>
            <a class="linkedin" href="https://www.facebook.com/saulesbendruomene" target="_blank">
              <Icon name="facebook" />
            </a>
          </Column>
          <!-- <Column class="social" :md="1" :xs="2">
            <a class="linkedin" href="linkedin.com/company/saules-bendruomene">
              <Icon name="facebook" />
            </a>
          </Column> -->
        </Row>
        <Row v-if="!isBigFooter" class="footerMenu small">
          <Column
            v-for="(menuItem, index) in menuItems"
            :key="index"
            :xs="6">
            <MenuLink
              :link="menuItem" />
          </Column>
        </Row>
      </div>
      <hr>
      <Row class="bottom">
        <Column :sm="6">
          <div class="privacyPolicy">
            <Route
              to="PrivacyPolicy">
              PRIVATUMO POLITIKA
            </Route>
          </div>
        </Column>
        <!-- <Column v-if="screenSizes.includes('md')" :md="2">
          <div class="contacts">
            <div>+370 633 82 277</div>
            <div>info@saulesbendruomene.lt</div>
          </div>
        </Column> -->
        <Column class="bottomRight" :sm="6">
          <div class="contacts">
            <a href="tel:+370 633 82 277">
              +370 633 82 277
            </a>
            <a href="mailto:info@saulesbendruomene.lt">
              info@saulesbendruomene.lt
            </a>
          </div>
          <div class="copyright">
            Visos teisės saugomos<br>{{ new Date().getFullYear() }} © SaulesBendruomene.lt
          </div>
        </Column>
      </Row>
    </Wrap>
  </div>
</template>

<script>
// import FooterSet from './FooterSet'
import MainLogo from 'components/TopBar/MainLogo'
import MenuLink from 'components/TopBar/MenuLink'
import { getters } from '../utils'

export default {
  components: { MainLogo, MenuLink },
  props: {
    showCompanyInfo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuItems: [
        {
          label: 'Saulės parkai',
          to: 'SolarPlants',
        },
        {
          label: 'Valstybės parama',
          to: 'ApvaIndividuals',
        },
        {
          label: 'Apie mus',
          to: 'AboutUs',
        },
        {
          label: 'D.U.K.',
          to: 'Faq',
        },
        {
          label: 'Naujienos',
          to: 'News',
        },
        {
          label: 'Konsultacija',
          to: 'Contact',
        },
      ],
      // companyInfo: {
      //   Įmonė: 'Saulės bendruomenė LT, UAB',
      //   Kodas: 305277449,
      //   'PVM kodas': 'LT100013065112',
      //   Adresas: 'Gedimino pr. 44A-501, LT-01110 Vilnius',
      //   'El. paštas': 'info@saulesbendruomene.lt',
      //   Telefonas: '+370 633 82 277',
      // },
    }
  },
  computed: {
    isBigFooter() {
      return this.screenSizes.includes('lg')
    },
    ...getters('isDesktop', 'screenSizes'),
  },
}
</script>

<style lang="scss">
.Footer {
  background: $black-bis;
  color: $white;

  a {
    color: $white;
    white-space: nowrap;

    &:hover {
      border-color: inherit;
    }
  }

  .top {
    .peak {
      padding: 2rem 0;
    }

    .footerMenu {
      align-items: center;
      display: flex;
      justify-content: center;

      &.small {
        justify-content: flex-start;
        margin-bottom: 1rem;

        .Column {
          margin: 1rem 0;
          // padding-bottom: 1rem;
          // padding-top: 1rem;
        }
      }
    }

    .social {
      align-items: center;
      display: flex;
      gap: 1rem;
      justify-content: flex-end;
    }

    .linkedin {
      background: $yellow;
      border-radius: $radius-round;
      color: $text-color;
      outline: 1px solid $white;
      outline-offset: 2px;
      padding: 1rem;
    }
  }

  // > .top {
  //   margin-bottom: 2rem;
  //   padding-top: 0.01rem;
  // }

  .bottom {
    align-items: center;
    color: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 2rem;
    padding-top: 2rem;

    hr {
      border-style: solid;
      border-width: 1px;
      color: inherit;
    }

    .bottomRight {
      @include md {
        text-align: end;
      }
    }

    .contacts {
      // display: flex;
      // flex-direction: column;
      margin: 1rem 0;
      text-align: center;
      @include sm {
        text-align: end;
      }
      @include lg {
        display: inline-block;
        margin-right: 2rem;
      }

      > a {
        display: block;

        &:hover {
          border-color: transparent;
          text-decoration: underline;
        }
      }
    }
  }

  .Wrap {
    padding: 0 2rem;
  }

  .privacyPolicy {
    margin-bottom: 1rem;
    text-align: center;
    @include sm {
      text-align: left;
    }
    @include md {
      margin-bottom: 0;
    }
  }

  .copyright {
    text-align: center;
    @include sm {
      text-align: right;
    }
    @include md {
      display: inline-block;
    }
  }

}

// html.webp {
//   #Footer {
//     &::before {
//       background-image: url('../../assets/landing/sweep-footer.webp');
//     }
//   }
// }

// #Footer-menu {
//   text-align: left;

//   .Column-footerSet {
//     @include md {
//       // display: flex;
//       // justify-content: center;
//     }
//   }
// }
</style>
