<template>
  <ProgressBar
    class="CardReservationProgress"
    :class="stepWidth"
    :steps="progressionSteps">
    <div
      v-if="contractMain"
      slot="step-2">
      <template>
        <Btn
          class="sm ib"
          @click="downloadMainContract">
          Atsisiųsti sutartį
        </Btn>
      </template>
    </div>
    <div
      v-if="paymentAdvance"
      slot="step-3">
      <template>
        <Btn
          v-if="!paymentAdvance.paidAt"
          class="sm ib"
          :isLoading="isSettingPaymentAsPaid"
          @click="setAdvancePaymentAsPaid">
          Pažymėti kaip apmokėtą
        </Btn>
        <Btn
          class="sm ib"
          @click="downloadAdvancePayment">
          Atsisiųsti
        </Btn>
      </template>
    </div>
    <div slot="step-4">
      <Btn
        v-if="contractTransferAcceptance"
        class="sm ib"
        @click="downloadTransferAcceptanceContract">
        Atsisiųsti
      </Btn>
    </div>
    <div
      v-if="paymentMain"
      slot="step-5">
      <Btn
        v-if="!paymentMain.paidAt"
        class="sm ib"
        :isLoading="isSettingPaymentAsPaid"
        @click="setMainPaymentAsPaid">
        Pažymėti kaip apmokėtą
      </Btn>
      <Btn
        class="sm ib"
        @click="downloadPaymentMain">
        Atsisiųsti
      </Btn>
    </div>
    <div slot="step-6">
      <!-- <div v-if="userStepPaymentMaintenance">
        {{ userStepPaymentMaintenance.executesAt | date }}
      </div> -->
      <!-- <Btn
        v-else-if="reservation.isReserved"
        class="sm ib"
        data-cy="createMaintenancePayment"
        :isLoading="isCreatingMaintenancePayment"
        @click="createMaintenancePayment">
        <template v-if="reservation.yearsFreeMaintenance">
          Už {{ reservation.yearsFreeMaintenance }} metų
        </template>
        Sukurti mokėjimą
      </Btn> -->
      <Btn
        v-if="paymentMaintenance && !paymentMaintenance.paidAt"
        class="sm ib"
        :isLoading="isSettingPaymentAsPaid"
        @click="setMaintenancePaymentAsPaid">
        Pažymėti kaip apmokėtą
      </Btn>
    </div>
  </ProgressBar>
</template>

<script>
import { CONTRACT_TYPES, PAYMENT_TYPES } from '@/store/consts'
import { actions, capitalize, getters } from 'views/utils'
import { last, pipe, pluck, prop, propEq, sortBy } from 'rambda'
import ProgressBar from 'components/admin/ProgressBar'

export default {
  components: {
    ProgressBar,
  },
  props: {
    paymentsMaintenance: Array,
    reservation: Object,
  },
  data() {
    return {
      isCreatingMainPayment: false,
      isCreatingMaintenancePayment: false,
      isSettingPaymentAsPaid: false,
      isSendingMainContract: false,
    }
  },
  computed: {
    stepWidth() {
      return `steps-count-${this.progressionSteps.length}`
    },
    contractMain() {
      return this.queryOne('contract', {
        reservationId: this.reservation.id,
        type: CONTRACT_TYPES.MAIN,
      })
    },
    contractTransferAcceptance() {
      return this.queryOne('contract', {
        reservationId: this.reservation.id,
        type: CONTRACT_TYPES.TRANSFER_ACCEPTANCE,
      })
    },
    paymentAdvance() {
      return this.queryOne('payment', {
        reservationId: this.reservation.id,
        type: PAYMENT_TYPES.ADVANCE,
      })
    },
    paymentMain() {
      return this.queryOne('payment', {
        reservationId: this.reservation.id,
        type: PAYMENT_TYPES.MAIN,
      })
    },
    paymentMaintenance() {
      if (!this.paymentsMaintenance) return null

      return pipe(
        sortBy(prop('id')),
        last,
      )(this.paymentsMaintenance)
    },
    progressionSteps() {
      const delayedMaintenanceFee = (() => {
        const hasDelayedInitialMaintenanceFee =
          this.userStepPaymentMaintenance &&
          !this.paymentMaintenance

        return hasDelayedInitialMaintenanceFee
          ? { theme: 'info' }
          : {}
      })()

      return [
        {
          name: 'Rezervavo',
          theme: 'success',
        },
        this.getThemeAndNameContract(CONTRACT_TYPES.MAIN, 'sutartis'),
        this.getThemeAndName(PAYMENT_TYPES.ADVANCE, 'avansas'),
        this.getThemeAndNameContract(CONTRACT_TYPES.TRANSFER_ACCEPTANCE, 'perdavimo akt.'),
        this.getThemeAndName(PAYMENT_TYPES.MAIN, 'mokėjimas'),
        {
          ...this.getThemeAndName(PAYMENT_TYPES.MAINTENANCE, 'priežiūros mok.'),
          ...delayedMaintenanceFee,
        },
      ]
    },
    project() {
      return this.queryOne('project', this.reservation.projectId)
    },
    stepPaymentMaintenance() {
      const outcome = this
        .getMatchingStepOutcome({
          action: 'created',
          type: 'payment',
          resourceType: PAYMENT_TYPES.MAINTENANCE,
          resourceId: null,
        }, this.project, this.reservation)

      const stepPaymentId = (outcome || {}).resourceId

      if (!stepPaymentId) return

      return this.queryOne('stepPayment', stepPaymentId)
    },
    stepPayments() {
      return this.query('stepPayment')
    },
    stepContracts() {
      return this.query('stepContract')
    },
    userStepsPaymentMaintenance() {
      return this.query('userStep', {
        reservationId: this.reservation.id,
        resourceType: PAYMENT_TYPES.MAINTENANCE,
        $sort: { executesAt: -1 },
      })
    },
    userStepPaymentMaintenance() {
      return this.userStepsPaymentMaintenance[0]
    },
    ...getters(
      'getMatchingStepOutcome',
      'getPaymentStatus',
      'getContractStatus',
    ),
  },
  methods: {
    async setPaymentAsPaid(id) {
      await this.PATCH_PAYMENT({
        id,
        paidAt: new Date(),
      })
    },
    async setAdvancePaymentAsPaid() {
      this.PROMPT({
        confirm: () => this.$wrap(
          async () => {
            await this.setPaymentAsPaid(this.paymentAdvance.id)
          },
          'isSettingPaymentAsPaid',
        ),
        text: 'Ar tikrai norite pažymėti Avansinį mokėjimą kaip apmokėtą?',
      })
    },
    setMainPaymentAsPaid() {
      this.PROMPT({
        confirm: () => this.$wrap(
          async () => {
            await this.setPaymentAsPaid(this.paymentMain.id)
          },
          'isSettingPaymentAsPaid',
        ),
        text: 'Ar tikrai norite pažymėti pagrindinį mokėjimą kaip apmokėtą?',
      })
      // this.PROMPT({
      //   confirm: this.setPaymentAsPaid(this.paymentMain.id),
      //   text: 'Ar tikrai norite pažymėti pagrindinį mokėjimą kaip apmokėtą?',
      // })
    },
    setMaintenancePaymentAsPaid() {
      this.PROMPT({
        confirm: () => this.$wrap(
          async () => {
            await this.setPaymentAsPaid(this.paymentMaintenance.id)
          },
          'isSettingPaymentAsPaid',
        ),
        text: 'Ar tikrai norite pažymėti priežiūros mokėjimą kaip apmokėtą?',
      })
      // this.PROMPT({
      //   confirm: this.setPaymentAsPaid(this.paymentMaintenance.id),
      //   text: 'Ar tikrai norite pažymėti priežiūros mokėjimą kaip apmokėtą?',
      // })
    },
    async createMaintenancePayment() {
      if (!this.stepPaymentMaintenance) {
        const errorMessage = 'Nepavyksta rasti priežiūros mokėjimo šablono'
        this.ERROR(errorMessage)
        throw new Error(errorMessage)
      }

      const payment = {
        reservationId: this.reservation.id,
        stepPaymentId: this.stepPaymentMaintenance.id,
      }

      this.$wrap(async () => {
        if (!this.reservation.yearsFreeMaintenance) {
          return await this.CREATE_PAYMENT(payment)
        }

        const executesAt = new Date()
        executesAt.setFullYear(executesAt.getFullYear() + this.reservation.yearsFreeMaintenance)

        await this.CREATE_USER_STEP({
          action: 'created',
          type: 'payment',
          resourceType: 'MAINTENANCE',
          executesAt,
          ...payment,
        })
      }, 'isCreatingMaintenancePayment')
    },
    async downloadMainContract() {
      await this.DOWNLOAD_FILE(this.contractMain)
    },
    async downloadTransferAcceptanceContract() {
      await this.DOWNLOAD_FILE(this.contractTransferAcceptance)
    },
    async downloadAdvancePayment() {
      await this.DOWNLOAD_FILE(this.paymentAdvance)
    },
    async downloadPaymentMain() {
      await this.DOWNLOAD_FILE(this.paymentMain)
    },
    getThemeAndName(stepPaymentType, paymentNameSuffix) {
      const stepPayments = this.stepPayments
        .filter(propEq('type', stepPaymentType))
      const stepPaymentIds = pluck('id', stepPayments)

      const payments = this.query('payment', {
        reservationId: this.reservation.id,
        stepPaymentId: {
          $in: stepPaymentIds,
        },
        $limit: stepPaymentIds.length,
        $sort: { id: -1 },
      })

      const payment = payments[payments.length - 1]

      if (!payment) {
        const name = `${capitalize(paymentNameSuffix)}`

        if (
          stepPaymentType === PAYMENT_TYPES.ADVANCE &&
          this.reservation.hasSkippedAdvance
        ) {
          return {
            name,
            theme: 'info',
            sub: 'Praleista',
          }
        }

        return {
          name,
          theme: null,
        }
      }

      const paymentStatus = this.getPaymentStatus(payment)
      const label = paymentNameSuffix.endsWith('a')
        ? paymentStatus.label.slice(0, -1)
        : paymentStatus.label

      const paidOrRefuned = payment.refundedAt || payment.paidAt

      return {
        theme: paymentStatus.theme,
        name: `${label} ${paymentNameSuffix}`,
        sub: paidOrRefuned
          ? this.$options.filters.dateTime(paidOrRefuned)
          : `iki ${this.$options.filters.dateTime(payment.deadlineAt)}`,
      }
    },

    getThemeAndNameContract(stepContractType, contractNameSuffix) {
      const stepContracts = this.stepContracts
        .filter(propEq('type', stepContractType))
      const stepContractIds = pluck('id', stepContracts)

      const contracts = this.query('contract', {
        reservationId: this.reservation.id,
        stepContractId: {
          $in: stepContractIds,
        },
        $limit: stepContractIds.length,
        $sort: { id: -1 },
      })

      const contract = contracts[contracts.length - 1]

      if (!contract) {
        const name = `${capitalize(contractNameSuffix)}`

        return {
          name,
          theme: null,
        }
      }

      const contractStatus = this.getContractStatus(contract)
      const label = contractNameSuffix.endsWith('a')
        ? contractStatus.label.slice(0, -1)
        : contractStatus.label

      return {
        theme: contractStatus.theme,
        name: `${label} ${contractNameSuffix}`,
        sub: contract.signedAt
          ? this.$options.filters.dateTime(contract.signedAt)
          : this.$options.filters.dateTime(contract.createdAt),
      }
    },

    // async sendMainContract() {
    //   this.$wrap(() => this.PATCH_CONTRACT({
    //     id: this.contractMain.id,
    //     isHidden: false,
    //   }), 'isSendingMainContract')
    // },
    ...actions(
      'CREATE_CONTRACT',
      'CREATE_PAYMENT',
      'CREATE_USER_STEP',
      'DOWNLOAD_FILE',
      'PATCH_CONTRACT',
      'PATCH_PAYMENT',
      'PROMPT',
    ),
  },
}
</script>

<style lang="scss">
.CardReservationProgress {
  &.steps-count-5 {
    .ProgressBarStep {
      width: calc(100% / 5);
    }
  }

  &.steps-count-6 {
    .ProgressBarStep {
      width: calc(100% / 6);
    }
  }
}
</style>
