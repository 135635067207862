<template>
  <button
    class="Btn"
    :class="classes"
    :type="type"
    @click="click"
    @keypress.enter="click">
    <Loader v-if="isLoading" />
    <slot v-else />
    <!-- <div class="content">
      <template v-if="isLoading">
        <Loader />
      </template>
      <div v-else class="text">
        <slot />
      </div>
      <div v-if="icon && !isLoading" class="circle">
        <Icon :name="icon" />
      </div>
    </div> -->
    <span v-if="icon && !isLoading">
      <Icon :name="icon" />
    </span>
  </button>
</template>

<script>
import Link from '../../mixins/Link'
import { actions } from '../../utils'

export default {
  mixins: [Link],
  props: {
    disabled: {
      type: Boolean,
    },
    icon: {
      type: String,
      // default: 'arrow-right',
    },
    iconSide: {
      default: 'right',
      type: String,
    },
    isLoading: {
      type: Boolean,
    },
    type: {
      default: 'button',
      type: String,
    },
  },
  computed: {
    classes() {
      return [
        this.disabled && 'disabled',
        this.isLoading && 'loading',
        this.icon && 'with-icon',
        this.icon && `icon-side-${this.iconSide}`,
      ]
    },
  },
  methods: {
    click(event) {
      if (this.isLoading || this.disabled) {
        event.preventDefault()
        return
      }

      this.go()
      this.$emit('update:isLoading', true)
      this.$emit('click')
    },
    ...actions(),
  },
}
</script>

<style lang="scss">
.Btn {
  @include trans;
  align-items: center;
  background: transparent;
  border: 2px solid $border-color-dark;
  border-radius: $radius-round;
  color: $text-color;
  cursor: pointer;
  display: flex;
  font-size: $h6;
  font-weight: $semibold;
  letter-spacing: 2.56px;
  line-height: 1.75rem;
  margin: 0;
  outline: 0;
  // padding: 0.5rem 1.5rem;
  padding: 0.4rem 0.5rem 0.4rem 0.8rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  // .content {
  //   align-items: center;
  //   display: flex;
  //   justify-content: space-between;
  // }

  // .circle {
  //   align-items: center;
  //   background: $yellow;
  //   border-radius: $radius-round;
  //   color: $text-color;
  //   display: flex;
  //   height: 2rem;
  //   justify-content: center;
  //   // margin-left: 3rem;
  //   margin-right: -0.8rem;
  //   padding-right: 1rem;
  //   width: 2rem;

  //   > .Icon {
  //     float: right;
  //     margin-left: 0.2rem;
  //     margin-right: -1rem;
  //   }
  // }

  &.arrow {
    display: flex;
    justify-content: space-between;
    padding-left: 1.5rem;

    &::after {
      background: $yellow;
      border-radius: 50%;
      content: url('../../../assets/arrow-right.svg');
      display: block;
      float: right;
      height: 2rem;
      margin-left: 1rem;
      padding-top: 0.3rem;
      width: 2rem;
    }

    &.no-text {
      padding-left: 0;

      &::after {
        margin-left: 0.5rem;
      }
    }

    &.up {
      transform: rotate(-90deg);
    }

    &.down {
      transform: rotate(90deg);
    }
  }

  &.minimalistic {
    border: none;
    padding: 0;

    &::after {
      background: transparent;
    }

    &:hover {
      background-color: transparent;
      color: $text-color;
    }

    // .circle {
    //   background: transparent;
    //   margin-left: 0;
    // }

    // &:hover {
    //   background: transparent;
    // }
  }

  &.invert {
    border-color: $white;
    color: $white;

    &:hover,
    &:focus {
      // color: $text-color;
    }
  }

  // link hover fix
  // &[href]:hover,
  // &[href]:focus {
  //   color: $white;
  // }

  // state
  &.loading {
    cursor: default;
  }

  // with icon
  &.with-icon {
    justify-content: space-between;
    padding-left: 1.5rem;
    padding-right: 1.25rem;

    &::after {
      background: $blue;
      border-radius: 50%;
      display: block;
      float: right;
      height: 2rem;
      margin-left: 1rem;
      padding-top: 0.3rem;
      width: 2rem;
    }

    > .Icon {
      float: right;
    }

    &.loading {
      padding-right: 1rem;
    }
  }

  &.icon-side-left {
    background: $red;
    padding-left: 0.75rem;

    > .Icon {
      float: left;
      margin-right: 1.5rem;
    }
  }

  // --- theme
  // &.inv {
  //   background: transparent;
  //   border-color: $main;
  //   color: $main;

  //   &:hover,
  //   &:focus {
  //     background: $main-light;
  //     color: $main;
  //   }
  // }

  &.link {
    background: transparent;
    border: none;
    border-bottom: 1px solid $link-color;
    border-radius: 0;
    color: $link-color;
    display: inline-block;
    padding: 0;
    width: auto;

    &.db {
      border-bottom: none;
    }

    &:hover,
    &:focus {
      border-bottom: 1px solid $yellow;
      // background: $main-light;
      // color: $link-color-hover;
    }
  }

  &.success {
    background: $success;
    border-color: $success;
    // color: $success-dark;
    &.inv {
      background: transparent;
      color: $success;

      &:hover,
      &:focus {
        background: $success;
        border-color: $success;
        color: $white;
      }
    }

    &:hover,
    &:focus,
    &:active {
      background: $success-lightish;
      border-color: $success-lightish;
    }
  }

  &.info {
    background: $info;
    border-color: $info;
    color: $white;

    &.inv {
      background: transparent;
      color: $info;

      &:hover,
      &:focus {
        background: $info-light;
      }
    }

    &:hover,
    &:focus {
      background: $info-lightish;
      border-color: $info;
      color: $white;
    }
  }

  &.neutral {
    background: $neutral;
    border-color: $neutral;

    &.inv {
      background: transparent;
      color: $neutral;
    }
  }

  // &.warning {
  //   background: $warning;
  //   border-color: $warning;
  //   color: #2b261e;
  // }

  &.danger {
    background: $danger;
    border-color: $danger;
    color: $white;

    &.inv {
      background: transparent;
      color: $danger;

      &:hover,
      &:focus {
        background: $danger-light;
      }
    }

    &:hover,
    &:focus {
      background: $danger-lightish;
      border-color: $danger;
      color: $white;
    }
  }

  &.disabled {
    background: $neutral;
    border-color: $border-color;
    color: $text-color;
    cursor: not-allowed;

    &.inv {
      background: transparent;
      border-color: $text-color-mid;
      color: $text-color-mid;
    }

    &:hover {
      background: $neutral;
      border-color: $border-color;
      color: $text-color;
    }
  }

  // --- size
  &.sm {
    font-size: $h7;
    padding: 0 1rem;
  }

  &.xs {
    font-size: $h8;
    line-height: 1.25rem;
    padding: 0 0.75rem;

    &.with-icon {
      padding: 0.25rem 1rem 0.25rem 0.5rem;
    }
  }

  &.lg {
    font-size: $h4;
    line-height: $lh * 1.5;
    padding: 0.5rem 2rem;
  }
  // &.xl {
  //   font-size: $h3;
  //   line-height: $lh * 2;
  //   padding: 1rem 4rem;
  // }

  // --- display
  &.ib {
    display: inline-block;
    width: auto;
  }

  &.no-border {
    background: transparent;
    border-width: 0;
  }

  &.db {
    display: block;
  }

  &:hover {
    background-color: $yellow;
    color: $text-color;
  }
}
</style>
