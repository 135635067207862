<template>
  <div id="AboutUs">
    <Hero src="../assets/aboutUs/hero.jpg">
      <h1>Apie vykdytoją</h1>
    </Hero>
    <SectionWhoAreWe />
    <SectionHowItWorks />
    <SectionWhyChooseUs />
    <FormSignupWide />
    <SectionBenefits />
    <SectionReservePlant />
  </div>
</template>

<script>
import FormSignupWide from './components/FormSignupWide'
import Hero from '../components/landing/Hero'
import Meta from '../mixins/Meta'
import SectionBenefits from './components/SectionBenefits'
import SectionHowItWorks from './components/SectionHowItWorks'
import SectionReservePlant from './components/SectionReservePlant'
import SectionWhoAreWe from './components/SectionWhoAreWe'
import SectionWhyChooseUs from './components/SectionWhyChooseUs'

export default {
  mixins: [
    Meta(
      'Pigi elektros energija | Elektros energijos gamyba',
      'Daugiau nei 500 sėkmingai įgyvendintų projektų Lietuvoje - nuo šiol pigi elektros energija prieinama kiekvienam Lietuvos gyventojui. Projektas SAULĖS BENDRUOMENĖ siekia užtikrinti, kad alternatyvi elektros energija būtų prieinama visoje šalyje ir elektros energijos gamyba patenkintų kiekvieno gyventojo poreikius.',
      require('@/assets/social/about.jpg'),
    ),
  ],
  components: {
    Hero,
    SectionBenefits,
    SectionHowItWorks,
    SectionReservePlant,
    SectionWhoAreWe,
    SectionWhyChooseUs,
    FormSignupWide,
  },
  data() {
    return {
      posts: null,
    }
  },
}
</script>

<style lang="scss">
#AboutUs {
  background: $white-ter;

  .Hero {
    @include roundSvg('../../assets/ellipse.svg');

    h1 {
      @include dotBefore(8rem);
    }

    img {
      filter: brightness(0.4);
    }
  }

  .Sect {
    margin-top: 4rem;

    &:first-of-type {
      margin-top: 0;
    }
  }

}

</style>
