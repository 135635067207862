import { AMENDMENT_TYPES, CONTRACT_TYPES } from '@/store/consts'
import { getterOptions } from '../utils'
import { propEq } from 'rambda'

export default {
  auto: '*',
  hasJoins: true,

  store: {
    state: {
      types: [
        {
          label: 'Rezervacinė',
          value: CONTRACT_TYPES.RESERVATION,
        },
        {
          label: 'Pagrindinė',
          value: CONTRACT_TYPES.MAIN,
        },
        {
          label: 'Perdavimo–priėmimo',
          value: CONTRACT_TYPES.TRANSFER_ACCEPTANCE,
        },
      ],

      amendmentTypes: [
        {
          label: 'Galingumo keitimas',
          value: AMENDMENT_TYPES.CAPACITY_CHANGE,
        },
      ],
      statuses: [
        {
          label: 'Pasirašyta',
          value: 'signedAt',
          theme: 'success',
        },
        {
          label: 'Sukurta',
          value: 'createdAt',
          theme: 'warning',
        },
      ],
    },
    getters: {
      contractTypes: state => state.types,
      contractTypesOptions: getterOptions('contractTypes'),

      amendmentTypes: state => state.amendmentTypes,
      amendmentTypesOptions: getterOptions('amendmentTypes', {
        label: 'Ne priedas',
        value: null,
      }),

      getContractTypeLabel: state => contractType => state.types
        .find(propEq('value', contractType))
        .label,

      getContractStatus: ({ statuses }) => (contract) => {
        return statuses
          .find(status => contract[status.value])
      },
    },
  },
}
