<template>
  <ValidationProvider
    #default="{ classes, errors }"
    :disabled="!rules && !isRequired"
    :name="name"
    :rules="rules">
    <div class="Field-cont">
      <label v-if="fieldLabel && !is('checkbox', 'radio')" :for="inputId">
        {{ fieldLabel }}
        <span v-if="isRequired" class="required">
          *
        </span>
      </label>
      <div class="Field" :class="[...fieldClasses, ...classes]">
        <Icon v-if="_icon" :name="_icon" />
        <input
          v-if="isInput"
          :id="inputId"
          v-bind="attrs"
          :type="_type"
          :value="_value"
          @change="change"
          @focusout="$emit('focusout')"
          @input="set">
        <div v-else-if="is('checkbox')" class="checkbox">
          <label :for="inputId">
            <input
              :id="inputId"
              v-bind="attrs"
              :checked="value ? 'checked' : ''"
              type="checkbox"
              :value="value"
              @change="setCheckbox($event.target.checked)"
              @input="setCheckbox($event.target.checked)">
            <template v-if="$slots.label">
              <slot name="label" />
            </template>
            <template v-else>
              {{ fieldLabel }}
            </template>
          </label>
        </div>
        <textarea
          v-else-if="is('textarea')"
          :id="inputId"
          v-bind="attrs"
          :value="_value"
          @change="change"
          @focusout="$emit('focusout')"
          @input="set($event)" />
        <select
          v-else-if="is('select')"
          :id="inputId"
          v-bind="attrs"
          :value="_value"
          @blur="setSelectIcon('down')"
          @change="set($event)"
          @focus="setFocus(true)"
          @focusout="setFocus(false);setSelectIcon('down')"
          @mousedown="setSelectIcon('up')"
          @mouseup="setSelectIcon('down')">
          <option v-if="$attrs.placeholder" data-type="object" disabled :value="null">
            {{ $attrs.placeholder }}
          </option>
          <option
            v-for="option in optionsFormatted"
            :key="`${option.label}`"
            :data-type="(typeof option.value)"
            :value="option.value">
            {{ option.label }}
          </option>
        </select>
        <transition name="slide-fade">
          <div v-show="errors[0]" class="Field-errors">
            {{ errors[0] }}
          </div>
        </transition>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { isNumeric, isObject, randomElementId } from '../../utils'

const entryToOption = ([value, label]) => ({ label, value })

const filters = {
  numeric: {
    out: value => isNumeric(value) ? Number(value) : null,
  },
  select: {
    // TODO: generalize into an auto type casting function
    out: value => {
      if (value === null || value === '' || value === 'null') return null
      if (value === 'true') return true
      if (value === 'false') return false

      return isNaN(Number(value)) ? value : Number(value)
    },
  },
}

export default {
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    icon: String,
    label: String,
    name: String,
    options: Array,
    required: {
      default: false,
      type: Boolean,
    },
    type: {
      type: String,
      default: 'text',
    },
    rules: [String, Object],
    value: {
      type: null,
    },
  },
  data() {
    return {
      inputIdFallback: randomElementId(),
      isFocused: false,
      selectIcon: 'down',
    }
  },
  computed: {
    attrs() {
      return {
        ...this.$attrs,
        'aria-label': this.ariaLabel,
        disabled: this.disabled,
        name: this.name,
        required: this.isRequired,
      }
    },
    ariaLabel() {
      const label = this.label ||
        this.$attrs.placeholder ||
        this.$attrs.ariaLabel ||
        this.$attrs['aria-label']

      if (typeof label !== 'string') return

      return label.replace('*', '').trim()
    },
    inputId() {
      return this.attrs.inputId || this.inputIdFallback
    },
    _icon() {
      return this.icon || {
        money: 'eur',
        select: this.selectIcon,
      }[this.type]
    },
    _type() {
      return {
        money: 'number',
      }[this.type] || this.type
    },
    _value() {
      return this.filterIn
        ? this.filterIn(this.value)
        : this.value
    },
    fieldClasses() {
      const conditions = [
        [this.disabled, 'disabled'],
        [this.isRequired, 'required'],
        [this.isFocused, 'focus'],
        [this.is('select'), 'trans'],
        [this._icon, 'with-icon'],
        [this.isInput, 'inp'],
        [this.isIconRight, 'icon-right'],
      ]

      return [
        `type-${this._type}`,
        ...conditions
          .filter(([condition]) => condition)
          .map(([_, classes]) => classes),
      ]
    },
    fieldLabel() {
      return this.label
      // const { attrs, label, _value } = this

      // if (this.type !== 'textarea' || !attrs.maxlength) {
      //   return label
      // }

      // const length = _value ? _value.length : 0

      // return `${label} (${length}/${attrs.maxlength})`
    },
    filterIn() {
      return {
        // check: filters.check.in,
        // time: filters.time.in,
      }[this.type]
    },
    filterOut() {
      return {
        text: value => value || null,
        // check: filters.check.out,
        money: filters.numeric.out,
        number: filters.numeric.out,
        select: filters.select.out,
      }[this.type]
    },
    isIconRight() {
      return ['money'].includes(this.type)
    },
    isInput() {
      return ['email', 'hidden', 'number', 'password', 'percent', 'text'].includes(this._type)
    },
    isRequired() {
      return this.required !== false
    },
    optionsFormatted() {
      if (Array.isArray(this.options)) {
        const optionFirst = this.options.find(option => option !== null)

        if (!optionFirst) return this.options

        return this.options.map((option) => {
          if (Array.isArray(option)) return entryToOption(option)
          if (isObject(option)) return option
          return { label: option, option }
        })
      } else if (isObject(this.options)) {
        // { value: label, ... }
        return Object
          .entries(this.options)
          .map(entryToOption)
      }

      return []
    },
  },
  methods: {
    change(event) {
      this.$emit('change', this.getEventValue(event))
    },
    getEventValue(event) {
      const value = isObject(event)
        ? event.target.value
        : event

      return this.filterOut
        ? this.filterOut(value)
        : value
    },
    is(...types) {
      return types.some(type => this.type === type)
    },
    set(event) {
      const value = this.getEventValue(event)
      this.$emit('update:value', value)
      this.$emit('input', value)
    },
    setCheckbox(event) {
      if (this.value === this.getEventValue(event)) return

      this.set(event)
    },
    setSelectIcon(value) {
      this.selectIcon = value
    },
    setFocus(value) {
      this.isFocused = value
    },
  },
}
</script>

<style lang="scss">
.Field-cont label,
label.Field-label {
  @include trans;
  color: inherit;
  display: block;
  font-size: $h5;
  font-weight: $semibold;
  position: relative;
  @include md {
    font-size: inherit;
    line-height: inherit;
  }
}

// TODO: remove stylelint disable
/* stylelint-disable no-descending-specificity */
.Field {
  input,
  select,
  textarea,
  input[type="number"],
  &.with-icon {
    background: $white;
    border: 1px solid $border-color;
    border-radius: $radius;
    outline: none;
    width: 100%;

    &.focus {
      border-color: $info;
      // box-shadow: 0 0 2px $info;
    }
  }

  textarea {
    background: $white;
    border: 1px solid $border-color;
    border-radius: 15px;
    outline: none;
    width: 100%;

    &.focus {
      border-color: $info;
      // box-shadow: 0 0 2px $info;
    }
  }

  input,
  select,
  textarea,
  input[type="number"] {
    font-size: inherit;
    line-height: inherit;
  }

  /* stylelint-disable no-duplicate-selectors */
  textarea {
    line-height: 1.5rem;
    min-height: 200px;
    padding: 0.75rem;
  }

  input[type="number"] {
    padding-right: 0;
  }

  input,
  select,
  option,
  textarea {
    @include trans;

    &.success {
      border-color: $success;
    }

    &.info {
      border-color: $info;
    }

    &:active,
    &:focus {
      border-color: $info;
      // box-shadow: 0 0 2px $info;
    }
  }

  input,
  select,
  option {
    padding: 0.25rem 0.5rem;
  }

  .checkbox {
    color: $text-color;
    cursor: pointer;
    display: inline-block;

    input {
      width: auto;
    }

    label {
      line-height: 1.5rem;
    }

    &.disabled {
      color: $text-color-light;
      cursor: not-allowed;

      label {
        color: $text-color-lighter;
      }
    }
  }

  .Field-errors {
    color: $danger;
    line-height: 1.5rem;
  }

  &.type-checkbox,
  &.type-radio {
    @include clearfix;

    input {
      margin-right: 0.5rem;
    }

    label {
      cursor: pointer;
    }
  }

  &.type-radio {
    margin-bottom: 0;
  }

  &.type-select {
    label {
      line-height: 1rem;
      margin: 0.75rem 0 0;
      padding-left: 0.75rem;
    }
  }

  &.with-icon {
    padding-left: 3rem;
    padding-right: 0;
    position: relative;

    input,
    select {
      border: none;

      &:active,
      &:focus {
        border-color: transparent;
        box-shadow: none;
      }
    }

    > .Icon {
      border-right: 1px solid $border-color;
      font-size: $h4;
      font-weight: $regular;
      left: 0;
      line-height: inherit;
      padding: 0.33rem 1rem 0.25rem;
      position: absolute;
      top: 0;
    }

    // modifiers
    &.trans {
      > .Icon {
        background: transparent;
        border: none;
      }
    }

    &.icon-right {
      padding-left: 0;
      padding-right: 2.75rem;

      > .Icon {
        border-right: 0;
        left: auto;
        right: 0;
      }
    }

    &.type-select {
      padding-left: 0;
      padding-right: 0;

      > .Icon {
        left: auto;
        padding: 0.375rem 0.5rem;
        pointer-events: none;
        position: absolute;
        right: 0;
      }

      select {
        padding-right: 2rem;
      }
    }
  }

  // state
  &.disabled {
    input {
      background: $white-bis;
    }
  }

  &.valid {
    input,
    &.with-icon {
      border-color: $success;

      &:focus {
        box-shadow: $shadow-lg-success;
      }
    }
  }

  &.invalid {
    margin-bottom: 0;

    input,
    &.with-icon {
      border-color: $danger;

      &:focus {
        box-shadow: $shadow-lg-danger;
      }
    }
  }
}

label span.required {
  color: $danger;
}

.Field-cont {
  font-size: $h5;
  line-height: 2rem;
  margin-bottom: 1rem;
  @include md {
    font-size: $h5;
    line-height: 2.5rem;
  }
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=checkbox] {
  padding: 10px;
  -ms-transform: scale(1.25) translateX(2px); /* IE */
  -moz-transform: scale(1.25) translateX(2px); /* FF */
  -webkit-transform: scale(1.25) translateX(2px); /* Safari and Chrome */
  -o-transform: scale(1.25) translateX(2px); /* Opera */
  transform: scale(1.25) translateX(2px);
  @include md {
    -ms-transform: scale(1.5) translateX(2px); /* IE */
    -moz-transform: scale(1.5) translateX(2px); /* FF */
    -webkit-transform: scale(1.5) translateX(2px); /* Safari and Chrome */
    -o-transform: scale(1.5) translateX(2px); /* Opera */
    transform: scale(1.5) translateX(2px);
  }
}
</style>
