<template>
  <div class="FormSignup">
    <FormFloat class="small-pad" :doShowTitle="doShowTitle" :title="title" @submit="signup">
      <transition name="slide-down">
        <Notice v-if="message" :class="message.theme">
          {{ message.text }}
        </Notice>
      </transition>
      <!-- <FieldManualCaptcha :value.sync="manualCaptcha" /> -->

      <FormUser
        :type="formType"
        :user="user" />

      <template v-if="isSignupOnReservation">
        <FieldSkipReservation
          v-if="project && project.canSkipReservation && isReservationPaymentSupported"
          :advanceAmountDefault="project.advancePaymentDefaultPercent"
          :value="hasSkippedReservation"
          @update:value="$up('hasSkippedReservation', $event)" />
        <FieldSkipAdvance
          v-if="project && project.canSkipAdvance"
          :advanceAmountDefault="project.advancePaymentDefaultPercent"
          :value="hasSkippedAdvance"
          @update:value="$up('hasSkippedAdvance', $event)" />
        <Field
          data-cy="promo"
          label="Nuolaidos kodas (Promo)"
          :maxlength="50"
          :value.sync="promoCode" />

        <Field
          label="Valstybės parama"
          :options="apvaOptions"
          required
          type="select"
          :value.sync="apvaChoice" />
        <Field
          v-if="apvaChoice === 'HAS_APVA' && apvaStageOptions.length > 1"
          label="Pasirinkite APVA etapą"
          :options="apvaStageOptions"
          :required="apvaChoice === 'HAS_APVA'"
          type="select"
          :value.sync="apvaStageId" />
      </template>

      <!-- <NoticeRecaptcha /> -->

      <Btn
        class="btn-signup arrow"
        :isLoading="isSigningUp"
        type="submit">
        Registruotis
      </Btn>
    </FormFloat>
    <div class="footer">
      <div class="content">
        <h4>Jau turite paskyrą?</h4>
        <Route class="Btn link minimalistic" to="Login">
          Prisijungti
        </Route>
      </div>
    </div>
  </div>
</template>

<script>
import { actions, getters } from '../../utils'
import { APVA_CHOICES } from '@/store/consts'
import FieldSkipAdvance from 'components/fields/FieldSkipAdvance'
import FieldSkipReservation from 'components/fields/FieldSkipReservation'
import FormFloat from '../FormFloat'
import FormUser from './FormUser'
import Message from '../../mixins/Message'
import Recaptcha from '../../mixins/Recaptcha'
import { softThrow } from '../../../bugsnag'

export default {
  mixins: [
    Message,
    Recaptcha,
  ],
  components: {
    FieldSkipAdvance,
    FieldSkipReservation,
    FormFloat,
    FormUser,
  },
  props: {
    formType: {
      type: String,
      default: 'signup',
    },
    title: {
      type: String,
      default: 'Registracija',
    },
    doShowTitle: {
      type: Boolean,
      default: true,
    },
    hasSkippedReservation: Boolean,
    hasSkippedAdvance: Boolean,
  },
  data() {
    return {
      user: {},
      isSigningUp: false,
      promoCode: null,
      apvaChoice: Object.keys(APVA_CHOICES)[0],
      apvaStageId: null,
      apvaOptions: Object.entries(APVA_CHOICES),
    }
  },
  computed: {
    apvaStageOptions() {
      return [
        [null, 'Pasirinkite etapą'],
        ...this.query('apvaStage', {
          isHidden: false,
          $sort: { startDate: -1 },
        })
          .map(apvaStage => [
            apvaStage.id,
            apvaStage.startDate
              .toLocaleString('lt-LT', { year: 'numeric', month: 'long', day: 'numeric' }),
          ]),
      ]
    },
    amount() {
      return Number(this.$route.params.amount) / 100
    },
    isSignupOnReservation() {
      return this.formType === 'reserve'
    },
    project() {
      return this.query('project', this.$route.params.id)
    },
    isReservationPaymentSupported() {
      return this.doesStepBranchSupportReservation(this.project.stepBranch)
    },
    ...getters('referrers', 'userFactory', 'doesStepBranchSupportReservation'),
  },
  methods: {
    async signup() {
      try {
        this.isSigningUp = true

        this.SET_SIGNUP_EMAIL(this.user.email)
        await this.SIGNUP(await this.injectRecaptcha({
          ...this.user,
          metaReferrers: this.referrers,
          ...this.isSignupOnReservation ? {
            reservationPresignup: {
              projectId: Number(this.$route.params.id),
              capacity: this.amount,
              promoCode: this.promoCode,
              hasSkippedReservation: this.hasSkippedAdvance
                ? true
                : this.hasSkippedReservation,
              hasSkippedAdvance: this.hasSkippedAdvance,
              apvaChoice: this.apvaChoice,
              apvaStageId: this.apvaStageId,
            },
          } : {},
        }, 'signup'))

        try {
          this.$ga.event('Signup', 'submit', 'email')
          this.$analytics.fbq.event('CompleteRegistration')
          if (this.user.hasSubscribed) {
            this.$ga.event('Newsletter', 'subscribe')
          }
        } catch (error) {
          softThrow(error)
        }

        this.$router.push({ name: 'SignupThanks' })
      } catch (error) {
        this.setErrorMessage(error)
        if (!error.data || !error.data.name) throw error
      } finally {
        this.isSigningUp = false
      }
    },
    ...actions(
      'SET_SIGNUP_EMAIL',
      'SIGNUP',
      'FETCH_APVA_STAGE',
    ),
  },
  beforeMount() {
    this.LOAD('FETCH_APVA_STAGE')
    this.user = this.userFactory({
      passwordMatch: '',
      code: null,
    })
  },
}
</script>

<style lang="scss">
.FormSignup {
  background: $white;
  border-radius: $radius 0;

  .footer {
    align-items: center;
    background: $blue;
    border-radius: $radius 0;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0;
    text-align: center;

    .content {
      width: 15rem;

      a {
        color: $white;
        margin-top: 1rem;
      }
    }
    // @include md {
    //   padding: 5rem 0;
    // }
  }
}
// .FormSect.lg-no-border {
//   @include lg {
//     border: 0;
//   }
// }
</style>
